import { useState, useEffect } from "react"
import { private_server, public_server, USER_TYPES } from "../../config"
import { isFormValid, onGetCurrentUserProfile, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility"
import { generic_errors } from "../../texts/esp/genericErrors"

const useSignInLayout = ({ language, content, onUpdateAuthStatus, onUpdateUserData, history}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    useEffect(() => {
        actions.onInitModule()
    }, [])
    
    const actions = {
        onInitModule: async() => {
            const _form = onInitForm(form, null,  content.form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onKeyPress: (event) => {
            if(event.key === 'Enter'){
              actions.onSubmit()
            }
        },
        onSubmit: async() => {

            const errors = isFormValid(form)

            if(errors && errors.length){
                console.log(errors)
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetFormData(form)
            data2send.device = "web"
            console.log(data2send)

            setSending(true)

            try {
                const response = await public_server.post(`/login`, data2send)
                const token_data = response.data.data
                await onSetTokenSession(token_data)
                updateAuthorizationHeader(private_server)
                const user_request = await private_server.get('/user/me')
                console.log(user_request.data.data.user)
                const _user =  user_request.data.data.user
                console.log('Mi usuario', _user)
                if(_user?.user_status_id !== 2){
                    onUpdateUserData(user_request.data.data.user)
                    onUpdateAuthStatus(true)
                    history.push('/')
                    setError(null)
                }else{
                    localStorage.removeItem('session_data')
                    setError('Usuario suspendido')
                }
                
                /* if(user_request.data.data.user.user_type_id === USER_TYPES.ADMIN ) {
                    onUpdateAuthStatus(true)}
                else{
                    onUpdateAuthStatus(false)
                    setError('Usuario no autorizado')
                } */
               
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }


            setSending(false)

            /* setLoading(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch('/users/me', data2send)
                const _user = await onGetCurrentUserProfile()
                onUpdateUserData(_user)

            } catch (error) {
                console.log(error)
            }
            setLoading(false) */
        }
    }

    const system = {loading, sending, error}

    return {system, form, actions}
}

export default useSignInLayout

const form_data = {
    email:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'email',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'email',
            //min:1, max:254
        }
    },
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'password',
            type:'password',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
}

import { Grid, IconButton, InputAdornment, makeStyles, Typography } from "@material-ui/core"
import SimpleCard from "../../../../../components/Structure/Cards/SimpleCard";
import InputForm from "../../../../../components/Forms/InputForm";
import InputInlineForm from "../../../../../components/Forms/InputInlineForm";
import AvatarPicture from "../../../../../components/Structure/DisplayData/AvatarPicture";
import { onGetFullname, onGetItemFromCatalogs } from "../../../../../shared/utility";
import GenericStatus from "../../../../../components/Structure/DisplayData/GenericStatus";
import RoundedButton from "../../../../../components/Actions/RoundedButton";
import InputSelectForm from "../../../../../components/Forms/InputSelectForm";
import SelectInlineForm from "../../../../../components/Forms/SelectInlineForm";
import usePassword from "./usePassword";
import { Visibility, VisibilityOff } from "@material-ui/icons";


const Password = ({ history, content, catalogs, onError}) => {

    const classes = useStyles()

    const { actions, form, values, system} = usePassword({history, content, catalogs, onError})

    return(
        <SimpleCard loading={system.loading} >
            <Typography className={classes.title} >{content.title}</Typography>
            <Typography variant='subtitle1' >{content.subtitle}</Typography>
            <div className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <InputForm data={form.previous_password} onChange={actions.onChangeForm} 
                            endAdornment={<InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>actions.onShowPassword('previous_password')}
                                            //onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {values.previous_password ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>}
                         />
                    </Grid>
                    <Grid item xs={12} >
                        <InputForm data={form.new_password} onChange={actions.onChangeForm}
                            endAdornment={<InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>actions.onShowPassword('new_password')}
                                            //onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {values.new_password ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>}
                         />
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.confirm_password} onChange={actions.onChangeForm} />
                    </Grid>
                </Grid>
            </div>
            <Grid container justifyContent="flex-end" spacing={3}>
                <Grid item>
                    <RoundedButton onClick={actions.onSubmit} >{content.button}</RoundedButton>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}



export default Password

const useStyles = makeStyles(theme => ({
    title:{
        fontWeight:600,
        fontSize:'1.6rem'
    },
    form:{
        marginTop:40,
        marginBottom:60
    }
   
    
}))
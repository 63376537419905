import { amber, blue, cyan, green, indigo, orange, purple, red, teal, pink, lime, grey, deepPurple } from "@material-ui/core/colors";

export const catalogs = {
    intercesion_days:[],
    intercesion_hours :[],
    user_statuses:[
        //{value:0, label:'Inactivo', color:red[700]},
        {value:1, label:'Activo', color:green[700]},   
        {value:2, label:'Suspendido', color:red[700]},  
    ],
    user_confirm_statuses:[
        {value:0, label:'Pendiente', color:amber[700]},
        {value:1, label:'Confirmado', color:'#843BA8'},     
    ],
    user_types:[
        {value:1, label:'Administrador', color:red[700]},
        {value:2, label:'Operador', color:red[700]},
        {value:3, label:'Cliente', color:red[700]},
        
    ],
    orders_statuses:[
        {value:1, label:'En proceso', color:"#A149C0", number:0.42},
        {value:2, label:'Entregado', color:green[700], number:0.23},
        {value:3, label:'Vencido', color:red[700], number:0.3},
        {value:4, label:'Cancelado', color:grey[700], number:0.05},
    ],
    applicants:[
        {value:1, label:'UNOPS', color:green[700]},
        {value:2, label:'INSABI-OM', color:red[700]},
    ],
    orders_years:[
        {value:'2021', label:'2021', color:green[700]},
        {value:'2022', label:'2022', color:red[700]},
    ],
    orders_events:[
        {value:1, label:'Patentes', color:"#A149C0", },
        {value:2, label:'Fuente única', color:green[700], },
        {value:3, label:'Oncológicos', color:red[700], },
        {value:4, label:'Priorizados', color:grey[700], },
    ],
    orders_invoiced:[
        {value:1, label:'Si', color:green[700]},
        {value:0, label:'No', color:red[700]},
    ],
    orders_delivery_types:[
        {value:1, label:'Directo', color:"#A149C0", },
        {value:2, label:'Operador logistico', color:green[700], },
        {value:3, label:'Domiciliado', color:red[700], },
    ],
    genders:[
        {value:1, label:'Masculino'},
        {value:2, label:'Femenino'},
        {value:3, label:'Otro'}
    ],
    specialities:[
        {id:1, label:'Dermatología'},
        {id:2, label:'Medicina General'},
        {id:3, label:'Nutrición'},
        {id:4, label:'Psicología'},
    ],
    currency:[
        {value:1, label:'Dolar (USD)', code:'USD', text_id:301},
        {value:2, label:'Peso (MXN)', code:'MXN', text_id:302},
    ],
    influencer_sizes:[
        {value:1, label:'Nano', color:pink[500]},
        {value:2, label:'Micro', color:blue[500]},
        {value:3, label:'Mid-tier', color:teal[500]},
        {value:4, label:'Macro', color:orange[500]},
        {value:5, label:'Mega', color:amber[500]},
    ],
    influencer_types:[
        {value:1, label:'Socialite', color:lime[500]},
        {value:2, label:'Creador de contenido', color:amber[500]},
        {value:3, label:'Celebridad', color:cyan[500]}
    ],
    membership_types_influencers:[
        {value:8, label:'Básica - mensual', color:blue[500]},
        {value:9, label:'Básica - anual', color:indigo[500]},
        {value:10, label:'Premium - mensual', color:deepPurple[500]},
        {value:11, label:'Premium - anual', color:blue[500]},
        {value:12, label:'Profesional - mensual', color:indigo[500]},
        {value:13, label:'Profesional - anual', color:deepPurple[500]}
    ],
    membership_types_managers:[
        {value:1, label:'Mensual', color:blue[500]},
        {value:2, label:'Anual', color:indigo[500]},
    ],
    membership_statuses:[
        {value:1, label:'Inactiva', color:blue[500]},
        {value:2, label:'Activa', color:indigo[500]},
        {value:3, label:'Suspendida por el usuario', color:blue[500]},
        {value:4, label:'Suspendida por falta de pago', color:indigo[500]},
    ],

    //nuevos
    tank_types:[
        {value:1, label:'Tanque 1', price:30.050},
        {value:2, label:'Tanque 2', price:200.000},
        {value:3, label:'Tanque 3', price:300.000},
    ],
    payment_types:[
        {value:1, label:'Efectivo'},
        {value:2, label:'Tarjeta de crédito'},
        {value:3, label:'Tarjeta de débito'},
    ],
    states:[
        {value:'AC', label:'ACRE'}, {value:'AL', label:'ALAGOAS'}, 
        {value:'AP', label:'AMAPÁ'}, {value:'AM', label:'AMAZONAS'},
        {value:'BA', label:'BAHIA'}, {value:'CE', label:'CEARÁ'},
        {value:'DF', label:'DISTRITO FEDERAL'}, {value:'ES', label:'ESPÍRITO SANTO'},
        {value:'GO', label:'GOIÁS'}, {value:'MA', label:'MARANHÃO'},
        {value:'MT', label:'MATO GROSSO'}, {value:'MS', label:'MATO GROSSO DO SUL'},
        {value:'MG', label:'MINAS GERAIS'}, {value:'PA', label:'PARÁ'},
        {value:'PB', label:'PARAÍBA'}, {value:'PR', label:'PARANÁ'},
        {value:'PE', label:'PERNAMBUCO'}, {value:'PI', label:'PIAUÍ'},
        {value:'RJ', label:'RIO DE JANEIRO'}, {value:'RN', label:'RIO GRANDE DO NORTE'},
        {value:'RS', label:'RIO GRANDE DO SUL'}, {value:'RO', label:'RONDÔNIA'},
        {value:'RR', label:'RORAIMA'}, {value:'SC', label:'SANTA CATARINA'},
        {value:'SP', label:'SÃO PAULO'}, {value:'SE', label:'SERGIPE'},
        {value:'TO', label:'TOCANTINS'}, 
    ],
    municipalities:[
        {value:3506003, label:'BAURU'}, 
        {value:3507506, label:'BOTUCATU'}, 
    ],
    products:[
        {value:1, label:'ENVASE DE GÁS GLP'},
        {value:2, label:'BOTIJÃO P13 13KG'},
        {value:3, label:'BOTIJÃO P5 5KG'},
        {value:4, label:'BOTIJÃO P2 2KG'},
    ],
    /* payment_types:[
        {value:1, label:'CARTÃO DE CRÉDITO'},
        {value:2, label:'CARTÃO DE DÉBITO'},
        {value:3, label:'DINHEIRO'},
    ] */
}

export default catalogs
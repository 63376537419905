import { Grid, makeStyles, Switch, Typography } from "@material-ui/core"
import useAccount from "./useTaxInfo";
import SimpleCard from "../../../../../components/Structure/Cards/SimpleCard";
import InputForm from "../../../../../components/Forms/InputForm";
import InputInlineForm from "../../../../../components/Forms/InputInlineForm";
import AvatarPicture from "../../../../../components/Structure/DisplayData/AvatarPicture";
import { onGetFullname, onGetItemFromCatalogs } from "../../../../../shared/utility";
import GenericStatus from "../../../../../components/Structure/DisplayData/GenericStatus";
import RoundedButton from "../../../../../components/Actions/RoundedButton";
import InputSelectForm from "../../../../../components/Forms/InputSelectForm";
import SelectInlineForm from "../../../../../components/Forms/SelectInlineForm";
import { useParams } from "react-router-dom";
import InputPhoneCodeForm from "../../../../../components/Forms/InputPhoneCodeForm";
import DeleteModal from "./modals/DeleteModal";


const TaxInfo = ({ history, content, catalogs, onError, language}) => {

    const {id} = useParams()
    const classes = useStyles()

    const { actions, form, user, system, modals, check} = useAccount({history, content, catalogs, onError})

    

    return(
        <SimpleCard loading={system.loading} >
            <DeleteModal open={modals.delete} onClose={()=>actions.onUpdateModal('delete', false)} onUpdateCompleted={()=>history.goBack()} id={id} 
                message={content.message_modal} />
            <Typography className={classes.title} >{content.title}</Typography>
            <div className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-end" spacing={2} alignItems='center' >
                            <Grid item ><Typography variant="subtitle1" color='textSecondary'>CNPJ</Typography></Grid>
                            <Grid item >
                                <Switch color='primary' checked={check} onChange={actions.onChangeCheck}/>
                            </Grid>
                            <Grid item ><Typography variant="subtitle1" color='textSecondary'>CPJ</Typography></Grid>
                        </Grid>               
                    </Grid>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.cnpj_cpf} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.business_name} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.commercial_name} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputInlineForm data={form.state_registration} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.municipal_registration} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.ncea} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputInlineForm data={form.address_line_1} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.ext_number} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.address_line_2} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputInlineForm data={form.neighborhood} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.zip_code} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <SelectInlineForm data={form.state_id} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectInlineForm data={form.municipality_id} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.email} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputInlineForm data={form.registration_date} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.fixed_phone} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputInlineForm data={form.cellphone} onChange={actions.onChangeForm} />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Grid container spacing={2} alignItems='center' >
                            <Grid item sm={3} xs={12}>
                                <Typography variant='subtitle1' color='textSecondary' >{content.form.fixed_phone.label}</Typography>
                            </Grid>
                            <Grid item sm={9} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} >
                                        <InputPhoneCodeForm data={form.mobile_country_code} onChange={actions.onChangeForm} noLabel/>
                                    </Grid>
                                    <Grid item xs={9} ><InputForm data={form.fixed_phone} onChange={actions.onChangeForm} noLabel/></Grid>
                                </Grid>      
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems='center' >
                            <Grid item sm={3} xs={12}>
                                <Typography variant='subtitle1' color='textSecondary' >{content.form.mobile.label}</Typography>
                            </Grid>
                            <Grid item sm={9} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} >
                                        <InputPhoneCodeForm data={form.mobile_country_code} onChange={actions.onChangeForm} noLabel/>
                                    </Grid>
                                    <Grid item xs={9} ><InputForm data={form.mobile} onChange={actions.onChangeForm} noLabel/></Grid>
                                </Grid>      
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </div>
            <Grid container justifyContent="flex-end" spacing={3}>
                <Grid item>
                    <RoundedButton color='white' onClick={()=>actions.onUpdateModal('delete', true)} >{content.delete_button}</RoundedButton>
                </Grid>
                <Grid item>
                    <RoundedButton onClick={actions.onSubmit} loading={system.sending} disabled={system.sending} >{content.save_button}</RoundedButton>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}



export default TaxInfo

const useStyles = makeStyles(theme => ({
    title:{
        fontWeight:600,
        fontSize:'1.6rem'
    },
    main_container:{
        padding:'30px 0px',
        borderBottom:'1px solid #E4E4E4',
        marginBottom:40
    },
    name_container:{
        display:'flex',
        width:'100%',
        //background:'red'
    },
    status_container:{
        background:'#F5F5F5',
        padding:'6px 12px',
        borderRadius:4
    },
    form:{
        marginBottom:60,
        marginTop:40
    }
   
    
}))
import React, { useEffect, useState } from 'react';
import axios from "axios"
import { Grid, Typography } from '@material-ui/core';

import { onGetErrorMessage, onGetFileExtension } from '../../../../../../shared/utility';

import SimpleModal from '../../../../../../components/Modals/SimpleModal'
import ActionModalBar from '../../../../../../components/Actions/ActionModalBar';
import InputFile from '../../../../../../components/Forms/InputFile'
import { updateAuthorizationHeader } from '../../../../../../shared/utility';
import { private_server } from '../../../../../../config';
import FileModal from '../../../../../../components/Modals/FileModal';
import system_translations from '../../../../../../texts/system_translations';


const UploadFileModal = ({ open, onClose, id, onCompleted, language}) => {

    console.log(id)
    const error_msg = system_translations[language].modals.upload_file_error

    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if(!open){
            setFile(null)
            setError(null)
            setLoading(false)
        }
    },[open])

    const onSubmit = async() => {
        try {
            if(!file){
                setError('Debe adjuntar un archivo')
                return
            }
            setLoading(true)

            const ext = {name:'document',ext:onGetFileExtension(file.name)}
            updateAuthorizationHeader(private_server)
            let response = await private_server.post(`/tanksale/${id}/file`, ext)
            const options = {headers: {'Content-Type': file.type}}
            delete axios.defaults.headers.common["Authorization"]
            await axios.put(response.data.data.url, file, options);
            onCompleted()
            onClose()

        } catch (error) {
            console.log(error)
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)    
        } 
        setLoading(false)
    }

    return ( 
        <FileModal loading={loading} error={error} onSubmit={onSubmit} open={open} onClose={onClose}
        onDelete={()=>setFile(null)} onChange={(file) => setFile(file)} file={file}/>
     );
}
 
export default UploadFileModal;


{/* <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color='primary'>Subir archivo</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputFile id='file' file={file} label='Adjuntar archivo' 
                            onChange={(file) => setFile(file)} onDelete={() => setFile(null)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar btnLabel='Subir' loading={loading} error={error} onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal> */}
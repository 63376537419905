import { makeStyles } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"

const tableAccordionStyles = makeStyles(theme => ({
    paper:{
        border:'none',
        boxShadow:'none',
        background:'transparent'
    },
    cell:{
        border:'none',
        //borderBottom:'3px solid #F8F8F8',
        background:'white',
        color:' #22252F',
        '&:first-child':{
            borderRadius:'10px 0px 0px 10px',
        },
        '&:last-child':{
            borderRadius:'0px 10px 10px 0px',
        }        
    },
    header:{
        fontSize:16,
        color:'#A3A3A3', 
        fontWeight:600
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        border:'none',   
        paddingTop:16,
        paddingBottom:16,
        background:'#F8F8F8'
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16,
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100,   
        borderCollapse: 'separate',
        borderSpacing: '0px 3px'
    },
    table_body:{

    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    empty:{
        width:'100%',
        height:'100%',
    },
    empty_container:{
        marginTop:100,
        marginBottom:40,
        borderRadius:'50%',
        background:'white',
        //boxShadow:'0px 10px 20px rgba(0, 0, 0, 0.02)',
        //border:'3px solid #E8EBF3',
        border:`3px solid ${theme.palette.primary.dark}`,
        width:140,
        height:140,
        padding:32,
        boxSizing:'border-box'
    },
    add_button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    delete_button:{
        border:'1px solid rgba(195, 195, 195, 0.27)',
        color:'#A9A9A9'
    },
    row_table:{
        //minWidth:1100,   
        borderCollapse: 'separate',
        borderSpacing: '0px 10px'
    },
    accordion_cell:{
        background:'#FDFDFD',
        border:'none',
        paddingTop:0,
        paddingBottom:0,
        borderRadius:'0px 0px 10px 10px'
        //color:' #22252F',
        /* '&:first-child':{
            borderRadius:'10px 0px 0px 10px',
        },
        '&:last-child':{
            borderRadius:'0px 10px 10px 0px',
        }  */
    },
    accordion_header:{
        fontWeight:600,
        color:'#7A7A7A',
        //borderBottom:'1px solid #E1E1E1'
    },
    accordion_table_cell:{
        paddingTop:20,
        border:'none',
        verticalAlign:'top'
    }

}))

export default tableAccordionStyles
import { Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, makeStyles, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import InputForm from "../../../../../components/Forms/InputForm"
import AvatarPicture from "../../../../../components/Structure/DisplayData/AvatarPicture"
import DataDisplayer from "../../../../../components/Structure/DisplayData/DataDisplayer"
import { isFormValid, onGetDateFormat, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import cx from 'classnames'
import { private_server } from "../../../../../config"
import moment from "moment"


const CreateOrder = ({ content, onClose, tank, client, onCompleted}) => {

    const classes = useStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [selected, setSelected] = useState(0)
    const [checked, setChecked] = useState(false)
    const [price, setPrice] = useState(null)

    useEffect(async() => {
        let _form = onInitForm(formData, null, content.form)
        setForm(_form)
        updateAuthorizationHeader(private_server)
        const _price = await private_server.get('/gasprice/current')
        setPrice(_price.data.data.gas_price.price)
    }, [])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
    }

    const onSubmit = async() => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        if (selected === 1) data2send.requested_kilograms = parseFloat(data2send.requested_kilograms) / parseFloat(price)
        data2send.client_tank_id = tank?.client_tank_id
        data2send.seller_id = tank?.seller_id
        data2send.requested_date = moment.utc().format('YYYY-MM-DD')
        data2send.price = parseFloat(price)

        console.log(data2send)
       

        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            console.log(data2send)
            await private_server.post('/gassale', data2send)
            onCompleted()
            onClose()
        } catch (error) {
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
        }
        setLoading(false)
    }

    return(
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Typography variant="h6">{content.title1}</Typography>
            </Grid>
            <Grid item xs={12} >
                <Grid container spacing={2} className={classes.info_container} >
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center' className={classes.user_container} >
                            <Grid item>
                                <AvatarPicture src={client?.image} size={54} />
                            </Grid>
                            <Grid item xs>
                                <Typography style={{fontSize:'1.2rem', fontWeight:600}}>{tank?.client}</Typography>           
                                <Typography style={{color: client && client.user_status_id === 1 ? '#3CB242' : '#F8D53B'}}>{client?.user_status}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}><DataDisplayer label={content.tare} data={tank?.cylinder_tare}  /></Grid>
                    <Grid item xs={12}><DataDisplayer label={content.type} data={tank?.tank_type} /></Grid>
                    <Grid item xs={12}><DataDisplayer label={content.expiration} data={onGetDateFormat(tank?.date, 'DD/MM/YYYY')} /></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} >
                <Grid container spacing={3} justifyContent="space-between" alignItems="center" >
                    <Grid item><Typography variant="h6">{content.title2}</Typography></Grid>
                    <Grid item>
                        <div className={classes.switch}>
                            {content.switch.map((item, ind) => 
                                <div key={item} onClick={()=>setSelected(ind)} className={cx({
                                    [classes.switch_button]:true, 
                                    [classes.switch_button_selected]: ind === selected,
                                })}  ><Typography>{item}</Typography></div>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm variant='filled' endAdornment={<InputAdornment position="end" >{selected === 0 ? 'KG' : '$'}</InputAdornment>} 
                        data={form.requested_kilograms} onChange={onChange} />
                    </Grid>
                </Grid>   
            </Grid>
            <Grid item xs={12}>
                <Grid container className={classes.bottom_section} justifyContent='center' spacing={3} >
                    <Grid item >
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} color="primary" defaultChecked />} label={content.alert_msg} />
                        </FormGroup>       
                    </Grid>
                    <Grid item>
                        <RoundedButton color='primary' onClick={onSubmit} loading={loading} disabled={!checked} >
                            {content.button}
                        </RoundedButton>
                    </Grid>
                    {error ? <Typography style={{marginTop:12}} color='error' align='center'>{error}</Typography> : null}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CreateOrder

const useStyles = makeStyles(theme => ({
    title_container:{
        padding:'20px 0px'
    },
    info_container:{
        borderTop:'1px solid #EBEBEB',
        borderBottom:'1px solid #EBEBEB',
        padding:'20px 0px 40px',
        margin:'0px 0px 14px'
    },
    user_container:{
        marginBottom:20
    },
    switch:{
        background:'#EFEFEF',
        borderRadius:20,
        display:'flex',
        padding:3
    },
    switch_button:{
        padding:'4px 24px',
        borderRadius:20,
        background:'#EFEFEF',
        color:'#838383',
        cursor:'pointer'
    },
    switch_button_selected:{
        background:'white',
        color:theme.palette.primary.main,
        cursor:'auto'
    },
    bottom_section:{
        marginTop:30,
    }
    
}))

const formData = {
    requested_kilograms: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'requested_kilograms',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    /* requested_price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'requested_price',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    }, */
}
import React from 'react'
import { Grid, Icon, InputBase, makeStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import useSearchBar from './useSearchBar'

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        //boxShadow:'0px 4px 7px rgba(0, 0, 0, 0.13)',
        border:'1px solid #E5E5E5',
        padding:'0px 24px',
        paddingRight:6,
        borderRadius:32,
        width:400,
        [theme.breakpoints.only('xs')]:{
            width:300
        }
    },
    icon:{
        background:theme.palette.primary.main,
        color:'white',
        borderRadius:'50%',
        padding:6,
        width:24,
        height:24,
        marginTop:4

    }
}))

const SearchBar = props => {

    const classes = useStyles()

    const { onUpdateTableFilter, tableFilter, onChange, search} = props
    const {inputRef, actions} = useSearchBar({ onUpdateTableFilter, tableFilter, onChange })


    return(
        <div className={classes.root}>
            <Grid container alignItems='center' spacing={1}> 
                <Grid item xs>
                    <InputBase value={search} placeholder='Buscar...' onChange={actions.onUpdateSearchValue} 
                        onKeyUp={actions.onStartCountDown} onKeyDown={actions.onStopCountDown} 
                        ref={inputRef} style={{ width:'100%'}} />
                </Grid>
                <Grid item>
                    <Icon className={classes.icon} fontSize='default'>search</Icon>
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchBar
import React from 'react'
import { connect } from 'react-redux';
import { Grid, Icon, makeStyles, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import SimpleModal from './SimpleModal'
import system_translations from '../../texts/system_translations'
import ActionModalBar from '../Actions/ActionModalBar';


const useStyles = makeStyles(theme => ({
    icon:{
        color:red[700],
        fontSize:54,
        border:`2px solid ${red[700]}`,
        padding:8,
        borderRadius:'50%'
    },
    icon_container:{
        width:78,
        height:78,
        background:'#F6F6F6',
        //padding:24,
        borderRadius:'50%',
        marginBottom:20,
        marginTop:10,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    button_container:{
        marginTop:20
    }
}))

const WarningModal = ({language, open, onClose, onSubmit, loading, error, disabled, message}) => {

    const content = system_translations[language].modals.warning
    const classes = useStyles()
   
    return(
        <SimpleModal open={open} onClose={onClose}  >
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction='column' alignItems='center' spacing={2}>
                            <Grid item>
                                <div className={classes.icon_container}>
                                    <img src={content.icon}  />
                                </div>
                            </Grid>
                            <Grid item>
                                {message ? <Typography variant='h6' style={{fontWeight:700}}  align='center'>{message}</Typography>
                                : <Typography variant='h6' style={{fontWeight:700}} align='center'>{content.message}</Typography>}
                            </Grid>
                            <Grid item>
                                <Typography color='primary' align='center'>{content.text1}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography align='center'>{content.text2}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.button_container} >
                            <ActionModalBar loading={loading} error={error} btnLabel={content.button} 
                            onSubmit={onSubmit} disabled={disabled}/>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}


const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}

export default connect(mapStateToProps)(WarningModal)
 
import moment from "moment"
import { useEffect, useState } from "react"
import { private_server } from "../../../config"
import {  onGetErrorMessage, onInitForm, updateAuthorizationHeader } from "../../../shared/utility"

const useProfileView = ({user,content, catalogs, onUpdateUserData}) => {

    const _content = content.views.account

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)


    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [selected_user, setSelectedUser] = useState(null)

    const [modals, setModals] = useState({
        add_user:false,
        edit_user:false,
        change_password:false,
        change_avatar:false,
    })

    useEffect(()=>{window.scrollTo(0,0)},[])

    useEffect(() => {
        if(user) actions.onInitModule()
    }, [user])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                const _user = await private_server.get(`/user/me`)
                setSelectedUser(_user.data.data.user)
                let _form = onInitForm(form, _user.data.data.user, _content.form)
                _form.user_type_id.options = catalogs.user_types
                _form.mobile_country_code.options = [{value:"+52", label:"+52"}]
                setForm(_form)
                setLoading(false)
                
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
        },
        onRefreshData: async() => {
            try {
                const _user = await private_server.get(`/user/me`)
                console.log(_user.data.data.user)
                setSelectedUser(_user.data.data.user)
                onUpdateUserData(_user.data.data.user)
            } catch (error) {
                console.log(error)
            }
        },
        /* onUpdateUsers: async() => {
            try {
                const _user = await private_server.get(`/user/me`)
                console.log(user)
                setSelectedUser(_user.data.data)
                onUpdateUserData(_user.data.data.user)
            } catch (error) {
                
            }
            
        },
        onUpdateQuery:(data) => {
            setQuery(data)
        },
        onSelectedItem: () => {

        },
        onUpdateUser: (data) => {
            setSelectedUser(data)
        },
        onUpdateTableFilter: (data) => {
            setTableFilter(data)
        }, */
        onUpdateModal: (_key, _value) => {
            let _modals = {...modals, [_key]:_value}
            setModals(_modals)
        }

    }

    const system = {loading, sending, error}
    const view_data = {selected_user, form}

    return {system, modals, view_data, actions}
}

export default useProfileView

const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
           
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
         
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
        
        },
        rules: {
            type: 'email',
        }
    },
    mobile: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,     
        },
        rules: {
            type: 'phone_number',
        }
    },
    user_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'user_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }

    },
    mobile_country_code: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'mobile_country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }

    },
    phone_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'phone_number',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'phone',
        }
    },
}
import React, { useState, useEffect } from 'react'
import { Grid, IconButton, InputAdornment, Typography } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../shared/utility'
import { useStyles } from './styles'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'
import { private_server } from '../../../../../config'
import { Visibility, VisibilityOff } from '@material-ui/icons'


const PasswordModal = props => {

    const { open, onClose, origin, actions, onRequestUsers, history, content } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    const [values, setValues] = useState({
        previous_password: false,
        new_password: false,
      });

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }else{
            let _form = onInitForm(form, null, content.form)
            setForm(_form)
        }
    }, [open])

    const onShowPassword = (key) => {
        //console.log(values[key])
        setValues({...values, [key]: !values[key]})
        let temp = { ...form }

        if(temp[key] && !values[key]){
            temp[key].config.type = 'text'
        }else{
            temp[key].config.type = 'password'
        }
        setForm(temp)
    }


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'new_password') temp['confirm_password'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        delete data2send.confirm_password
        console.log(data2send)
        //delete data2send.passwordConfirmation
        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.post(`/user/me/passwordchange`, data2send)
            await actions.onRefreshData()
            actions.onUpdateModal('change_password', false)
            
        } catch (error) {
            console.log(error)
            const _error = onGetErrorMessage(error)
            setError(_error?.message || 'No se pudo actualizar la contraseña, verfique su contraseña anterior')
            setLoading(false)
        }
        setLoading(false)

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <Typography variant='h6' style={{ fontWeight: 600 }}>{content.title}</Typography>
                <Typography variant='body1' color='textSecondary'>Por favor ingresa los datos solicitados</Typography>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.previous_password} onChange={onChange} 
                                endAdornment={<InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=>onShowPassword('previous_password')}
                                                //onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                >
                                                {values.previous_password ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.new_password} onChange={onChange}
                                endAdornment={<InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=>onShowPassword('new_password')}
                                                //onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                >
                                                {values.new_password ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.confirm_password} onChange={onChange} 
                                endAdornment={<InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={()=>onShowPassword('confirm_password')}
                                //onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {values.confirm_password ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div style={{width:'100%', textAlign:'center'}}>
                    <RoundedButton color='primary'  onClick={onSubmit} loading={loading}>
                        {content.button}
                    </RoundedButton>
                    <Typography color='error' align='center'>{error}</Typography>
                </div>
            </div>
        </SimpleModal>
    )
}

export default PasswordModal


const formData = {
    previous_password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'previous_password',
            type: 'password',
            fullWidth: true,
            label: 'Contraseña anterior',
            helperText: 'Debe contener 6 a 20 caracteres',
            //autoComplete:'new-password'
        },
        rules: {
            type: 'distance',
            min:6, max:20
        }
    },
    new_password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'new_password',
            type: 'password',
            fullWidth: true,
            label: 'Nueva contraseña',
            helperText: 'Debe contener 6 a 20 caracteres'
        },
        rules: {
            type: 'distance',
            min:6, max:20
        }
    },
    confirm_password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'confirm_password',
            type: 'password',
            fullWidth: true,
        
        },
        rules: {
            type: 'equals',
        }
    },
}
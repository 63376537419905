import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../shared/utility'
import SimpleModal from '../../../../components/Modals/SimpleModal'
import { private_server, USER_TYPES } from '../../../../config'
import { modalStyles } from '../../../../styles/modalStyles'
import NewSale from './steps/NewSale'
import CreateOrder from './steps/CreateOrder'



const AddModal = props => {

    const { open, onClose, actions,  content, onCompleted } = props

    const classes = modalStyles()
    

    const [step, setStep] = useState(1)
    const [tank, setTank] = useState(null)
    const [client, setClient] = useState(null)

    const onGoToStep = (_step) => setStep(_step)
    const onSetTank = (_tank) => setTank(_tank)
    const onSetClient = (_client) => setClient(_client)

    useEffect(async() => {
        if(open){
            setStep(1)
        }
    }, [open])

    let contentStep = null

    switch (step) {
        case 1:
            contentStep = <NewSale content={content.steps.new_sale} onGoToStep={onGoToStep} onSetTank={onSetTank} onSetClient={onSetClient} />
            break;
        case 2:
            contentStep = <CreateOrder content={content.steps.create_order}  tank={tank} client={client} onClose={onClose} 
                            onCompleted={onCompleted} />
            break;
        default:
            break;
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='xs'>
            <div >
                <Typography variant='h6'  style={{ fontWeight: 600 }}>{content.add.title}</Typography>
                <div className={classes.form} >
                    {contentStep}
                </div>
            </div>
        </SimpleModal>
    )
}

export default AddModal


const formData = {
    requested_kilograms: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'requested_kilograms',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    requested_price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'requested_price',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    payment_ticket_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'payment_ticket_number',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    payment_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'payment_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    tank_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'tank_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    
}
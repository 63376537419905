import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import SimpleModal from '../../../../components/Modals/SimpleModal'
import { private_server, USER_TYPES } from '../../../../config'
import { modalStyles } from '../../../../styles/modalStyles'
import CreateSale from './steps/CreateSale'


const AddModal = props => {

    const { open, onClose, actions,  content, onCompleted, catalogs, user } = props

    const classes = modalStyles()
    

    const [step, setStep] = useState(1)
    

    const onGoToStep = (_step) => setStep(_step)
    

    useEffect(async() => {
        if(open){
            setStep(1)
        }
    }, [open])

    let contentStep = null

    switch (step) {
        case 1:
            contentStep = <CreateSale content={content.steps.create_sale} onGoToStep={onGoToStep} onCompleted={onCompleted} catalogs={catalogs} 
                            user={user} onClose={onClose} />
            break;
        default:
            break;
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='xs'>
            <div >
                <Typography variant='h6'  style={{ fontWeight: 600 }}>{content.add.title}</Typography>
                <div className={classes.form} >
                    {contentStep}
                </div>
            </div>
        </SimpleModal>
    )
}

export default AddModal


const formData = {
    requested_kilograms: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'requested_kilograms',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    requested_price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'requested_price',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    payment_ticket_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'payment_ticket_number',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    payment_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'payment_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    tank_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'tank_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    
}
import { Grid, makeStyles, Typography } from "@material-ui/core"
import useAccount from "./useAccount";
import SimpleCard from "../../../../../components/Structure/Cards/SimpleCard";
import InputForm from "../../../../../components/Forms/InputForm";
import InputInlineForm from "../../../../../components/Forms/InputInlineForm";
import AvatarPicture from "../../../../../components/Structure/DisplayData/AvatarPicture";
import { onGetFullname, onGetItemFromCatalogs } from "../../../../../shared/utility";
import GenericStatus from "../../../../../components/Structure/DisplayData/GenericStatus";
import RoundedButton from "../../../../../components/Actions/RoundedButton";
import InputSelectForm from "../../../../../components/Forms/InputSelectForm";
import SelectInlineForm from "../../../../../components/Forms/SelectInlineForm";
import { useParams } from "react-router-dom";
import DeleteModal from "./modals/DeleteModal";
import ChangeImageModal from "./modals/ChangeImageModal";
import InputPhoneCodeForm from "../../../../../components/Forms/InputPhoneCodeForm";


const Account = ({ history, content, catalogs, onError, language}) => {

    const {id} = useParams()
    const classes = useStyles()

    const { actions, form, user, system, modals} = useAccount({history, content, catalogs, onError})

    return(
        <SimpleCard loading={system.loading} >
            <DeleteModal open={modals.delete} onClose={()=>actions.onUpdateModal('delete', false)} onUpdateCompleted={()=>history.goBack()} id={id} 
                message={content.message_modal} /> 
            <ChangeImageModal open={modals.change_picture} id={user?.id_user} onCompleted={actions.onInitModule} onClose={() => actions.onUpdateModal('change_picture',false)} 
                language={language}/>
            <Typography className={classes.title} >{content.title}</Typography>
            <div className={classes.main_container}>
                <Grid container spacing={3} >
                    <Grid item>
                        <AvatarPicture src={user?.image} size={100} onChangePicture={()=>actions.onUpdateModal('change_picture', true)}  />
                    </Grid>
                    <Grid item xs>
                        <div className={classes.name_container}>
                            <Typography style={{fontSize:'1.2rem', fontWeight:600, marginRight:16}}  >{onGetFullname(user?.first_name, user?.last_name)}</Typography>
                            <div className={classes.status_container} >
                                <GenericStatus catalogs={catalogs.user_statuses} value={user?.user_status_id} text_styles={{fontWeight:600}} />
                            </div>
                        </div>
                        {/* <Typography  color='primary' >{onGetItemFromCatalogs(catalogs.user_types, user?.user_type_id)}</Typography> */}
                        <Typography >{user?.email}</Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.first_name} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputInlineForm data={form.last_name} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputInlineForm data={form.email} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems='center' >
                            <Grid item sm={3} xs={12}>
                                <Typography variant='subtitle1' color='textSecondary' >{content.form.mobile.label}</Typography>
                            </Grid>
                            <Grid item sm={9} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} >
                                        {/* <InputSelectForm data={form.mobile_country_code} onChange={actions.onChangeForm} noLabel/> */}
                                        <InputPhoneCodeForm data={form.mobile_country_code} onChange={actions.onChangeForm} noLabel/>
                                    </Grid>
                                    <Grid item xs={9} ><InputForm data={form.mobile} onChange={actions.onChangeForm} noLabel/></Grid>
                                </Grid>      
                            </Grid>
                        </Grid>
                        {/* <InputInlineForm data={form.mobile} onChange={actions.onChangeForm} /> */}
                    </Grid>
                    {/* <Grid item xs={12}>
                        <SelectInlineForm data={form.user_type_id} onChange={actions.onChangeForm} />
                    </Grid> */}
                </Grid>
            </div>
            <Grid container justifyContent="flex-end" spacing={3}>
                <Grid item>
                    <RoundedButton color='white' onClick={()=>actions.onUpdateModal('delete', true)} >{content.delete_button}</RoundedButton>
                </Grid>
                <Grid item>
                    <RoundedButton onClick={actions.onSubmit} loading={system.sending} disabled={system.sending} >{content.save_button}</RoundedButton>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}



export default Account

const useStyles = makeStyles(theme => ({
    title:{
        fontWeight:600,
        fontSize:'1.6rem'
    },
    main_container:{
        padding:'30px 0px',
        borderBottom:'1px solid #E4E4E4',
        marginBottom:40
    },
    name_container:{
        display:'flex',
        width:'100%',
        //background:'red'
    },
    status_container:{
        background:'#F5F5F5',
        padding:'6px 12px',
        borderRadius:4
    },
    form:{
        marginBottom:60
    }
   
    
}))
import warning_icon from '../../assets/icons/warning.svg'

const helperText = 'Este campo debe tener al menos 1 caracter y máximo 255'

const modals_translations = {
    
    warning:{
        icon:warning_icon,
        message:'¿Estás seguro?',
        text1:'Está acción no podrá ser revertida',
        text2:'Da click en el botón para confirmar',
        button:'Eliminar'
    },
    picture:{
        title:'Imagen',
        placeholder:'Arrastre o de click para subir una imagen',
        img_selected:'Imagen seleccionada',
        action_button:'Guardar'
    },
    profile_picture:'Foto de perfil',
    evidence_picture:'Subir evidencia',
    upload_file:{
        title:'Subir archivo',
        input_file:'Adjuntar archivo',
        button:'Subir',
        
    },
    upload_file_error:'Debe adjuntar un archivo'

    //views
    
    
}

export default modals_translations


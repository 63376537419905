import { makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

export const modalStyles = makeStyles(theme=>({
    title:{
        fontWeight:600,
        marginBottom:20
    },
    form:{
        paddingTop:theme.spacing(6),
        paddingBottom:theme.spacing(4),
    },
    button_container:{
        textAlign:'center',
        marginBottom:12
    },
    /* linkContainer:{
        marginTop:16,
        marginBottom:16
    },
    link:{
        color:theme.palette.secondary.main,
        fontWeight:500,
        fontFamily:theme.typography.fontFamily,
        textAlign:'center',
        '&:hover':{cursor:'pointer', color:theme.palette.secondary.dark,}
    },
    successIcon:{
        fontSize:64,
        color:green[700],
        border:`2px solid ${green[700]}`,
        borderRadius:'50%',
        marginTop:32,
        marginBottom:32
    } */
}))
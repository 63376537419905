import { Button, Grid, Icon, IconButton, makeStyles, Typography } from "@material-ui/core"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture'
import moment from 'moment'


//pruebas
import nouser from '../../../../assets/nouser.png'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { onGetDateFormat, onGetFullname } from "../../../../shared/utility"
import { nodata } from "../../../../config"
import StatusData from "../../../../components/Structure/DisplayData/StatusData"
import { catalogs } from "../../../../texts/esp/catalogs"
import { blueGrey } from "@material-ui/core/colors"
import VerticalData from "../../../../components/Structure/DisplayData/VerticalData"
import GenericStatus from "../../../../components/Structure/DisplayData/GenericStatus"
import InputInlineForm from '../../../../components/Forms/InputInlineForm'
import InputSelectForm from "../../../../components/Forms/InputSelectForm"
import InputForm from "../../../../components/Forms/InputForm"
import SelectInlineForm from '../../../../components/Forms/SelectInlineForm'

const UserInfo = ({user, onUpdatePassword, onEditUserData, actions, form, content, system}) =>{

    const classes = useStyles()

    let fullname = ''
    let first_name = ''
    let username = ''
    let lastname = ''
    let usergender = '-'
    let status = {}
    let mobile = ''
    let phone = ''
    let email = ''
    let status_id
    let user_type = ''
    let birth_date = ''

    if(user){
        fullname = onGetFullname(user.first_name,user.last_name)
        first_name = user.first_name ? user.first_name : nodata
        lastname = user.last_name ? user.last_name : nodata
        mobile = user.mobile ? user.mobile : nodata
        phone = user.phone ? user.phone : nodata
        username = user.username
        email = user.email
        user_type = user.user_type
        birth_date = onGetDateFormat(user.birth_date)

        status_id = user.user_status_id
        //status = catalogs.user_statuses.find(el => el.value === status_id)
        const temp_gender = catalogs.genders.find(el => el.value === user.gender_id)
        if(temp_gender) usergender = temp_gender.label
    }

    return(
        <div> 
                <Grid container spacing={4}>
                    <Grid item xs={12} md={5} >
                        <SimpleCard>
                            <div className={classes.container1}>
                                <div className={classes.avatar_container} >
                                    <Grid container direction="column" alignItems="center" spacing={2}>
                                        <Grid item>
                                        <AvatarPicture size={125} src={user ? user.image : null} nopicture={nouser} 
                                            can_edit onChangePicture={() => actions.onUpdateModal('change_avatar', true)}/>
                                        </Grid>
                                        <Grid item>
                                            <div style={{marginTop:16, marginBottom:8,}}><Typography variant='subtitle1' style={{lineHeight:'normal'}}>{fullname}</Typography></div>
                                            <Typography align="center" variant="body2" color='primary'>{user_type}</Typography>
                                        </Grid>                                  
                                        <Grid item>
                                            <Grid container spacing={1} justifyContent="center">
                                                <Grid item><Icon color="primary">mail</Icon></Grid>
                                                <Grid item><Typography>{user ? user.email : nodata}</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={1} justifyContent="center">
                                                <Grid item><Icon color='primary'>phone</Icon></Grid>
                                                <Grid item><Typography>{user && user.mobile ? user.mobile : nodata}</Typography></Grid>
                                            </Grid>
                                        </Grid>                
                                    </Grid>
                                    <Button className={classes.button} onClick={onUpdatePassword}>{content.profile.change_password}</Button>
                                </div>
                            </div>
                        </SimpleCard>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <SimpleCard  >
                            <Typography className={classes.title} >{content.profile.info}</Typography>
                            {/* <div className={classes.main_container}>
                                <Grid container spacing={3} >
                                    <Grid item>
                                        <AvatarPicture src={user?.image} size={100} onChangePicture={()=>actions.onUpdateModal('change_picture', true)}  />
                                    </Grid>
                                    <Grid item xs>
                                        <div className={classes.name_container}>
                                            <Typography style={{fontSize:'1.2rem', fontWeight:600, marginRight:16}}  >{onGetFullname(user?.first_name, user?.last_name)}</Typography>
                                            <div className={classes.status_container} >
                                                <GenericStatus catalogs={catalogs.user_statuses} value={user?.user_status_id} text_styles={{fontWeight:600}} />
                                            </div>
                                        </div>
                                        <Typography  color='primary' >{onGetItemFromCatalogs(catalogs.user_types, user?.user_type_id)}</Typography>
                                        <Typography >{user?.email}</Typography>
                                    </Grid>
                                </Grid>
                            </div> */}
                            <div className={classes.form}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <InputInlineForm data={form.first_name} onChange={actions.onChangeForm} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputInlineForm data={form.last_name} onChange={actions.onChangeForm} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputInlineForm data={form.email} onChange={actions.onChangeForm} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center' >
                                            <Grid item sm={3} xs={12}>
                                                <Typography variant='subtitle1' color='textSecondary' >{content.form.mobile.label}</Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item md={3} ><InputSelectForm data={form.mobile_country_code} onChange={actions.onChangeForm} noLabel/></Grid>
                                                    <Grid item md={9} xs><InputForm data={form.mobile} onChange={actions.onChangeForm} noLabel/></Grid>
                                                </Grid>      
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectInlineForm data={form.user_type_id} onChange={actions.onChangeForm} />
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid container justifyContent="flex-end" spacing={3}>
                                {/* <Grid item>
                                    <RoundedButton color='white' onClick={()=>actions.onUpdateModal('delete', true)} >{content.delete_button}</RoundedButton>
                                </Grid> */}
                                <Grid item>
                                    <RoundedButton onClick={actions.onSubmit}  >{content.save_button}</RoundedButton>
                                </Grid>
                            </Grid>
                        </SimpleCard>
                    </Grid>
                </Grid>
        </div>
    )

}

const useStyles = makeStyles(theme => ({
    container1:{
        display:'flex',
        justifyContent:'center',
    },
    avatar_container:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        padding:'20px 16px',
    },
    button:{
        border:`1px solid ${theme.palette.primary.main}`,
        //boxShadow:'0px 0px 5px rgba(237, 28, 36, 0.33)',
        borderRadius:20,
        color:theme.palette.primary.main,
        padding:'10px 20px',
        textTransform:'none',
        marginTop:20
    },
    name:{
        marginTop:20
    },
    container2:{
        padding:20,
        [theme.breakpoints.down('sm')]:{
            padding:8,
        }
    },
    topbar:{
        marginBottom:20
    },
    delete_button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
        //marginRight:16
    },
    title:{
        fontWeight:600,
        fontSize:'1.6rem'
    },
    form:{
        marginBottom:40,
        marginTop:40
    }
    
}))

export default UserInfo

const TitleText = (props) => {
    return(
        <Typography variant='body2' style={{color:'#909090'}}>{props.children}</Typography>
    )
}
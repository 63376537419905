import React, { useEffect } from 'react';
import system_translations from '../../texts/system_translations';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import useSignInLayout from './useSignInLayout';
import InputForm from '../../components/Forms/InputForm';
import logo from '../../assets/logo.png'
import RoundedButton from '../../components/Actions/RoundedButton';
import ForgottenPasswordModal from '../../modals/ForgottenPasswordModal/ForgottenPasswordModal';



const SignInLayout = ({user, history, language, onUpdateAuthStatus, onUpdateUserData, onUpdateModalStatus, modals}) => {

    const classes = useStyles()
    const content = system_translations[language].layouts.signin
    const {system, form, actions} = useSignInLayout({language, content, onUpdateAuthStatus, onUpdateUserData, onUpdateModalStatus, history})

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    let contentRedirect = null
    //if(user) contentRedirect = <Redirect to='/influencers'/>

    return ( 

        <div className={classes.root}>
            <ForgottenPasswordModal open={modals.recover_password} onClose={()=>onUpdateModalStatus({recover_password:false})} />
            {contentRedirect}
                <div className={classes.container}>
                    <div className={classes.logo_container}>
                        <img src={content.logo} className={classes.logo} />
                        <Typography variant='h6' style={{marginTop:20}} >{content.title}</Typography>
                    </div>
                    <div className={classes.form}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <InputForm data={form.email} onChange={actions.onChangeForm}
                                onKeyPress={actions.onKeyPress}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputForm data={form.password} onChange={actions.onChangeForm}
                                onKeyPress={actions.onKeyPress}/>
                            </Grid>
                        </Grid>
                    </div>
                    {/*<Grid container justifyContent='flex-end'>
                        <Grid item>
                            <div onClick={()=>onUpdateModalStatus({recover_password:true})} className={classes.link}>{content.forgotten_password}</div>
                        </Grid>
    </Grid>*/}
                    <div style={{marginTop:40, marginBottom:20, textAlign:'center'}}>
                        <RoundedButton  loading={system.sending} onClick={actions.onSubmit}>
                            {content.button}
                        </RoundedButton>
                    </div>
                    {system.error ? (
                        <Typography align='center' color='error' variant='subtitle2'>{system.error}</Typography>
                    ) : null}
                </div>
            <div className={classes.position_deco1}>
                <div className={classes.circle} >
                    <div className={classes.deco} style={{padding:'15%'}} >
                        <div className={classes.deco} style={{padding:'20%'}} >
                            <div className={classes.deco} style={{padding:'25%'}}>
                                <div className={classes.deco}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.position_deco2}>
                <div className={classes.circle} >
                    <div className={classes.deco} style={{padding:'15%'}} >
                        <div className={classes.deco} style={{padding:'20%'}} >
                            <div className={classes.deco} style={{padding:'25%'}}>
                                <div className={classes.deco}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        fontFamily:theme.typography.fontFamily,
        background:'#F8F8F8',
        height:'100vh',
        overflowX:'hidden',
        overflowY:'hidden',
        
        
        
        //width:1400,
        //width:'100%'
        //width:'100vw'
    },
    container:{
        background:'white',
        borderRadius:50,
        boxShadow:'0px 20px 30px rgba(0, 0, 0, 0.05)',
        width:400,
        padding:40,
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        zIndex:10,
        [theme.breakpoints.only('xs')]:{
            width:'70%'
        }
    },
    logo_container:{
        textAlign:'center',
        marginBottom:30
    },
    logo:{
        width:150
    }, 
    position_deco1:{
        position:'absolute',
        bottom:'-28%',
        left:'-18%',
        [theme.breakpoints.down('sm')]:{
            display:'none'
        }
    },
    position_deco2:{
        position:'absolute',
        top:'-30%',
        right:'-16%',
        //overflowX:'hidden'
        [theme.breakpoints.down('sm')]:{
            display:'none'
        }
    },
    circle:{
        //position:'absolute',
        height:700,
        width:700,
        padding:'15%',
        //background:'red',
        border: '1px dashed #DFDFDF',
        borderRadius:'50%',
        //boxSizing:'border-box'
    },
    link:{
        marginTop:8,
        color:theme.palette.primary.main,
        fontWeight:500,
        fontSize:'0.9rem',
        textDecoration:'underline',
        cursor:'pointer'
    },
    deco:{
        border: '1px dashed #DFDFDF',
        borderRadius:'50%',
        width:'100%',
        height:'100%',
        boxSizing:'border-box'
    }
}))

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language,
        modals:state.modals
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        onUpdateToken: (token) => dispatch({type:actionTypes.SYS_UPDATE_TOKEN, token}),
        onUpdateSessionStatus: (session_status) => dispatch({type:actionTypes.SYS_UPDATE_SESSION_STATUS, session_status}),
        onUpdateModalStatus: (modal)=>dispatch({type:actionTypes.SYS_UPDATE_MODAL, modal})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(SignInLayout);
import { useState } from "react";
import { makeStyles } from "@material-ui/core"
import { connect } from 'react-redux';
import system_translations from "../../../texts/system_translations";
import NarrowPage from "../../../components/Structure/Layouts/NarrowPage";
import Account from "./views/Account/Account";
import Password from "./views/Password/Password";
import useClientView from "./useClientView";
import Tanks from "./views/Tanks/Tanks";
import Transactions from "./views/Transactions/Transactions";
import TaxInfo from "./views/TaxInfo/TaxInfo";



const ClientView = ({language, history, user, match}) => {

    const classes = useStyles()
    const content = system_translations[language].views.client
    const catalogs = system_translations[language].catalogs


    const {view_data, actions, modals, system} = useClientView({history, user, match})


    const [view, setView] = useState(1)

    let contentView = null

    switch(view){
        case 1:
            contentView = <Account content={content.views.account} catalogs={catalogs} onError={actions.onSetError} history={history} language={language} />
            break
        case 2:
            contentView = <TaxInfo content={content.views.tax_info} catalogs={catalogs} onError={actions.onSetError} history={history} language={language} />
            break
        case 3:
            contentView = <Tanks content={content.views.tanks} catalogs={catalogs} onError={actions.onSetError} language={language}  />
            break
        case 4:
            contentView = <Transactions content={content.views.transactions} catalogs={catalogs} onError={actions.onSetError} />
            break
        case 5:
            contentView = <Password content={content.views.password} catalogs={catalogs} onError={actions.onSetError} />
            break
        default:
            break
    }




    return(
        <NarrowPage title={content.title} setReturn loading={system.loading} error={system.error} onCloseError={actions.onClearError} 
            menu={content.menu} selected={view} onChange={(_id)=>setView(_id)} breadcrumbs={content.breadcrumbs}  >   
            {contentView}
        </NarrowPage>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language:state.language, 
    }
}

export default connect(mapStateToProps)(ClientView)

const useStyles = makeStyles(theme => ({
    edit_button:{
        background:theme.palette.primary.main,
        borderRadius:20,
        boxShadow:'0px 2px 7px rgba(0, 0, 0, 0.31)',
        textTransform:'none',
        color:'white',
        padding:'8px 28px'
    },
    delete_button:{
        background:'white',
        color:theme.palette.primary.main,
        boxShadow:'0px 5px 7px rgba(0, 0, 0, 0.12)'
    }
    
}))
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, makeStyles, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import InputForm from "../../../../../components/Forms/InputForm"
import AvatarPicture from "../../../../../components/Structure/DisplayData/AvatarPicture"
import DataDisplayer from "../../../../../components/Structure/DisplayData/DataDisplayer"
import { isFormValid, onGetDateFormat, onGetErrorMessage, onGetFormData, onGetFullname, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import cx from 'classnames'
import InputSelectForm from "../../../../../components/Forms/InputSelectForm"
import UserAvatarName from '../../../../../components/Structure/DisplayData/UserAvatarName'
import ClientModal from "../ClientModal"
import { private_server } from "../../../../../config"
import moment from "moment"


const CreateSale = ({ content, catalogs, onCompleted, onClose, user}) => {

    const classes = useStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [checked, setChecked] = useState(false)

    const [client_modal, setClientModal] = useState(false)
    const [client, setClient] = useState(null)
    const [tank_type, setTankType] = useState(null)

    const onSetClient = (_client) => setClient(_client)

    useEffect(() => {
        let _form = onInitForm(formData, null, content.form)
        _form.payment_type_id.options = catalogs.payment_types
        _form.tank_type_id.options = catalogs.tank_types
        setForm(_form)
    }, [])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'tank_type_id') setTankType(temp[id].value)
        setForm(temp)
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
    }

    const onSubmit = async() => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        data2send.date = moment.utc().format('YYYY-MM-DD')
        data2send.client_tank_id = client?.id_client_tank
        data2send.seller_id = user.id_user
        

        console.log(data2send)
       

        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            console.log(data2send)
            await private_server.post('/tanksale', data2send)
            onCompleted()
            onClose()
        } catch (error) {
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
        }
        setLoading(false)
    }



    return(
        <Grid container spacing={3}>
            <ClientModal open={client_modal} onClose={()=>setClientModal(false)} content={content.modal} onSetClient={onSetClient} tank_type_id={tank_type} />
            <Grid item xs={12} >
                <Typography variant="h6">{content.title}</Typography>
            </Grid>
            <Grid item xs={12} >
                <InputSelectForm data={form.tank_type_id} onChange={onChange} /> 
            </Grid>
            <Grid item xs={12} >
                <Typography style={{color:'#A5A5A5', fontSize:'0.75rem'}}  >{content.client}</Typography>
                <div className={classes.client_container}>
                    <Grid container justifyContent="space-between">
                        <Grid item><UserAvatarName name={client?.client} image={client?.image} /> </Grid>
                        <Grid item><Button disabled={tank_type === null} onClick={()=>setClientModal(true)} color='primary'>{content.client_button}</Button></Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} >
                <InputSelectForm data={form.payment_type_id} onChange={onChange} /> 
            </Grid>
            <Grid item xs={12} >
                <InputForm data={form.payment_ticket_number} onChange={onChange} /> 
            </Grid>
            <Grid item xs={12}>
                <Grid container className={classes.bottom_section} alignItems='center' direction="column" spacing={3} >
                    <Grid item >
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} color="primary" defaultChecked />} label={content.alert_msg} />
                        </FormGroup>       
                    </Grid>
                    <Grid item>
                        <RoundedButton color='primary' loading={loading} disabled={!checked} onClick={onSubmit} >
                            {content.button}
                        </RoundedButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CreateSale

const useStyles = makeStyles(theme => ({
    client_container:{
        borderRadius:12,
        background:'#F3F3F3',
        width:'100%',
        padding:'10px 14px',
        boxSizing:'border-box'
    },
    bottom_section:{
        marginTop:30,
    }
    
}))

const formData = {
    payment_ticket_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'payment_ticket_number',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    payment_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'payment_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    tank_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'tank_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
}
import React from 'react'
import { Card, CircularProgress, makeStyles } from '@material-ui/core'
import cx from 'classnames'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{
        borderRadius:15,
        boxShadow:'0px 7px 13px rgba(0, 0, 0, 0.04)',
        padding:32,
        [theme.breakpoints.down('sm')]:{
            padding:16,
        }
    },
    clickable:{
        '&:hover':{
            cursor:'pointer',
            background: grey[100]
        }
    },
    loading_container:{
        height:400,
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }
}))

const SimpleCard = ({children, padding, onClick, loading, loading_height}) => {
    const classes = useStyles()

    let styles = {}
    if(padding === 'small') styles = {...styles, padding:12}
    if(padding === 'none') styles = {...styles, padding:0}

    return(
        <Card className={cx({
            [classes.root]:true,
            [classes.clickable]: onClick ? true: false
        })} style={styles} onClick={onClick} >
            {loading ? <div className={classes.loading_container} style={{height: loading_height ? loading_height : 400}}>
                <CircularProgress size={32} /></div> 
            : children}
        </Card>
    )
}

export default SimpleCard
import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../shared/utility'
import { useStyles } from './styles'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'
import { private_server, USER_TYPES } from '../../../../../config'
import { modalStyles } from '../../../../../styles/modalStyles'
import InputPhoneCodeForm from '../../../../../components/Forms/InputPhoneCodeForm'


const AddUserModal = props => {

    const { open, onClose, view_data, actions, onRequestUsers, history, user, content, catalogs } = props

    const classes = modalStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    catalogs.user_types.splice(2, 1)
    //console.log(user_types)

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }else{
            let _form = onInitForm(form, null, content.form)
            _form.user_type_id.options = catalogs.user_types
            _form.mobile_country_code.options = [{value:"+52", label:"+52"}]
            setForm(_form)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['confirm_password'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            console.log(errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        delete data2send.passwordConfirmation
        data2send.user_status_id = 1
       

        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            console.log(data2send)
            await private_server.post('/user', data2send)
            await actions.onInitModule()
            onClose()
        } catch (error) {
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
        }
        setLoading(false)

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <Typography variant='h6'  style={{ fontWeight: 600 }}>{content.add.title}</Typography>
                <Typography variant='body1' color='textSecondary' >{content.subtitle}</Typography>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}  md={6}>
                            <InputForm data={form.first_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}  md={6}>
                            <InputForm data={form.last_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={5} ><InputPhoneCodeForm data={form.mobile_country_code} onChange={actions.onChangeForm} noLabel/></Grid>
                                <Grid item xs={7} ><InputForm data={form.mobile} onChange={onChange} /></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.email} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelectForm data={form.user_type_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.social_security_number} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.password} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.confirm_password} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.button_container}>
                    <RoundedButton color='primary' fullWidth  onClick={onSubmit} loading={loading}>
                        {content.add.button}
                    </RoundedButton>
                </div>
                <Typography color='error' align='center'>{error}</Typography>
            </div>
        </SimpleModal>
    )
}

export default AddUserModal


const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    user_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'user_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
            min: 8, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
        },
        rules: {
            type: 'email',
        }
    },
    password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label: 'Contraseña',
            helperText: 'Debe contener 6 a 20 caracteres',
            autoComplete:'new-password'
        },
        rules: {
            type: 'distance',
            min:6, max:20
        }
    },
    confirm_password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'confirm_password',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'equals',
        }
    },
    mobile: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'phone',
        }
    },
    social_security_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'social_security_number',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    mobile_country_code: {
        value: '52',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'mobile_country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }

    },
}
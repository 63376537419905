import gas from '../../assets/icons/gas.svg'

export const translations = {
    title:'Ventas de gas',
    message_modal:'¿Estás seguro de eliminar a este usuario?',
    table:{
        header:[
            {id:0, label:''},
            {id:1, label:'ID'},
            {id:2, label:'Fecha de op.'},
            {id:3, label:'Cliente'},
            {id:4, label:'Vendedor'},
            {id:5, label:'No. de kg'},
            {id:6, label:'Precio'},
            {id:7, label:'Método de pago'},
            {id:8, label:'Ticket'},
            {id:9, label:'¿Pagado?'},
            {id:10, label:'¿Entregado?'},
            {id:11, label:'Folio fiscal'},
        ],
        nodata:'No existen ventas registradas',
        empty:gas,
        details:{
            title:'Detalles de la transacción',
            header:[
                {id:1, label:'TANQUE'},
                {id:2, label:'TRUCK'},
                {id:3, label:'OPERACIONES'}
            ],
            body:{
                type:'Tipo',
                cylinder_tag:'ID del tanque',
                valve_tag:'ID de la válvula',
                tare:'Tara',
                name:'Nombre',
                gas_price:'Precio de gas',
                service_date:'Fecha de servicio',
                operation_date:'Fecha de operación',
                requested_kg:'Kilogramos solicitados',
                filled_kg:'Kilogramos llenados',
                price:'Precio de venta'
            }
        

        },
        
    },
    modal:{
        add:{
            title:'Nueva venta',
        },
        steps:{
            new_sale:{
                form:{
                    cylinder_tag:{label:'Tag tanque', helperText:'Campo requerido'},
                    valve_tag:{label:'Tag válvula', helperText:'Campo requerido'},
                },
                button:'Buscar'
            },
            create_order:{
                title1:'Información del tanque',
                title2:'Pedido',
                type:'Tipo',
                expiration:'Expiración',
                tare:'Tara',
                switch:['Peso', 'Dinero'],
                alert_msg:'¿Estás seguro de la cantidad?',
                button:'Crear pedido'
            },   
        },
    }
    
}

export default translations
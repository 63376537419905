import { Avatar, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { isValidDocument } from '../../../shared/utility'
import { CONFIG_SETTINGS } from '../../../config';



const useStyles = makeStyles(theme => ({
    avatar:{
        '& .MuiAvatar-img':{
            objectFit:'contain',

        },
        border:'1px solid #BFBFBE',
        width:32, height:32,
    },
    noimage:{
        '& .MuiAvatar-img':{
            objectFit:'contain',
            //width:20,
            //height:20
        },
        width:32, height:32,
        padding:8,
        background:theme.palette.primary.main,
        boxSizing:'border-box',
        //background:'#F8F8F8',
        //border:'1px solid #BFBFBE'
    }
}))

const UserAvatarName = props => {

    const classes = useStyles()
    const {image, name, variant, nopicture} = props

    const [error, setError] = useState(false)
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : nopicture

    return(
        <div>
            <Grid container alignItems='center' wrap='nowrap' spacing={1}>
                <Grid item>
                    {isValid && !error ? <Avatar src={selectedImage} alt='' className={classes.avatar} imgProps={{ onError:()=>setError(true), onLoad:()=>setError(false)}} /> : 
                    <Avatar className={classes.noimage}>D</Avatar>}
                </Grid>
                <Grid item>
                    <Typography variant={variant ? variant : 'body1'} >{name}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default UserAvatarName
import React from 'react';
import PictureModal from '../../../../../../components/Modals/PictureModal/PictureModal';
import system_translations from '../../../../../../texts/system_translations';


const ChangeImageModal = (props) => {

    const { id, open, onClose, onCompleted, language} = props
    const title = system_translations[language].modals.profile_picture

    return ( 
        <PictureModal open={open} onClose={onClose} onCompleted={onCompleted}
            title={title} submit_url={`/user/${id}/image`} delete_url={`/user/${id}/image?name=image`} />
     );
}
 
export default ChangeImageModal;
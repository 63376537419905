import general_es from './esp/general-translations'
import front_end_errors_es from './esp/frontend-erros'
import sigin_layout_es from './esp/signin-translations'
import sigup_layout_es from './esp/signup-translations'
import users_es from './esp/users-translations'
import user_es from './esp/user-translations'
import catalogs_es from './esp/catalogs'
import clients_es from './esp/clients-translations'
import client_es from './esp/client-translations'
import tanksales_es from './esp/tanksales-translations'
import gassales_es from './esp/gassales-translations'

import modals_es from './esp/modals-translations'

const system_translations = {
    es:{
        errors: front_end_errors_es,
        general:general_es,
        catalogs:catalogs_es,
        layouts:{
            signin: sigin_layout_es,
            signup: sigup_layout_es
        },
        views:{
            user:user_es,
            users:users_es,
            clients:clients_es,
            client:client_es,
            tank_sales:tanksales_es,
            gas_sales:gassales_es
        },
        modals:{
            warning:modals_es.warning,
            picture:modals_es.picture,
            tank:modals_es.tank,
            profile_picture:modals_es.profile_picture,
            upload_file:modals_es.upload_file,
            upload_file_error:modals_es.upload_file_error,
            evidence_picture:modals_es.evidence_picture
        },
    },
    eng:{
        
    }
}

export default system_translations
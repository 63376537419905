import React, { useState, useEffect } from 'react';
import { Breadcrumbs, Button, Grid, Icon, IconButton, makeStyles, Typography, Link } from '@material-ui/core';
import { blueGrey, grey } from '@material-ui/core/colors';
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import ErrorModal from '../../Modals/ErrorModal';



const NarrowPage = ({title, children, classes, menu, selected, onChange, setReturn, history, error, onCloseError, breadcrumbs, fullWidth}) => {

    const _classes = useStyles()

    const errorModal = <ErrorModal open={Boolean(error)} message={error} onClose={onCloseError}/>

    return ( 
        <div className={cx(_classes.root, classes)} style={{width: fullWidth ? '100%': undefined}} >
            {errorModal}
            <div className={_classes.title_container}>
                {setReturn ? (
                    <IconButton style={{marginRight:8, padding:0}} color='primary' onClick={() => history.goBack()}><Icon fontSize='large'>keyboard_backspace</Icon></IconButton>
                ) : null}
                <Typography className={_classes.title} variant='h5'>{title}</Typography>
                
            </div> 
            <div style={{marginBottom:40, marginLeft:46}} >
                {breadcrumbs ? 
                    <Breadcrumbs>
                        <Link className={_classes.link} onClick={() => history.goBack()}>{breadcrumbs.label} </Link>  
                    </Breadcrumbs> 
                : null}  
            </div>
            <Grid container spacing={3} className={_classes.container} >
                <Grid item >
                    {menu && menu.lenght !== 0 ? 
                        <div className={_classes.menu}>
                            <Grid container spacing={2}  >
                                {menu.map(item => {
                                    return <Grid item md={12} xs='auto' key={item.label} ><CustomButton onClick={() => onChange(item.id)} selected={item.id === selected}>{item.label}</CustomButton></Grid>
                                }) }
                            </Grid>
                        </div> 
                    : null}
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                    <div >
                        {children}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root:{
        width:1200,
        //overflowX:'hidden',
        //margin:'0px auto',
        paddingTop:80,
        //background:'green',
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]:{
            width:'100%',
            padding:'24px 40px',
            paddingTop:80,
        },
        [theme.breakpoints.down('sm')]:{
            //width:'100%',
            padding:'24px 20px',
            paddingTop:40,
        }
        
    },
    link:{
        color:'#A5A5A5',
        cursor:'pointer'
    },
    title_container:{
        display:'flex',
        //marginBottom:40,
        alignItems:'center'
    },
    title:{
        fontWeight:600
        //display:'inline-block',
        //background:'red',
        //margin:'auto'
    },
    line_decoration:{
        marginTop:16,
        height:2,
        background:grey[200],
        
    },
    menu:{
        width:200,
        
        //display:'inline',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            //background:'pink',
        }
    },
    container:{
        flexWrap:'nowrap',
        [theme.breakpoints.down('sm')]:{
            flexWrap:'wrap'
        }
    }
}))
 
export default withRouter(NarrowPage);



const useButtonStyles = makeStyles(theme => ({
    root:{position:'relative'},
    button:{
        background:'transparent',
        color:'#909090',
        borderRadius:20,
        padding:'8px 16px',
        fontWeight:600,
        '&:hover':{background:blueGrey[200]},
        textTransform:'none',
        '& .MuiButton-label':{
            justifyContent:'left'
        }
       
    },
    selected:{
        color:'#22252F',
        background:theme.palette.primary.dark,
        '&:hover':{background:theme.palette.primary.dark}
        //border:`1px solid ${theme.palette.primary.main}`
    }
}))

const CustomButton = props => {
    const classes = useButtonStyles()
    const {children, selected, onClick, number} = props



    return(
        <div >
            <Button onClick={onClick} fullWidth className={cx({
                [classes.button]:true,
                [classes.selected]:selected
            })}>{children}</Button> 
        </div>
    )
}
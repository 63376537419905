import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { isFormValid, onGetSelectedFormData, onInitForm, onSetErrorsToForm } from "../../../../../shared/utility"
import { private_server } from "../../../../../config"
import {  onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"

const useAccount = ({ content, catalogs, onError}) => {

    const {id} = useParams()
    console.log(id)

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    //const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    const [user, setUser] = useState(null)

    const [modals, setModals] = useState({
        delete:false,
        change_picture:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

  

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const _user = await private_server.get(`/user/${id}`)
                setUser(_user.data.data.user)
                let _form = onInitForm(form, _user.data.data.user, content.form)
                //_form.user_type_id.options = catalogs.user_types
                _form.mobile_country_code.options = [{value:"+52", label:"+52"}]
                setForm(_form)
                setLoading(false)       
            } catch (error) {
                setLoading(false)
                console.log(error)
                const _error = onGetErrorMessage(error)
                onError(_error ? _error.message : null)
                //setError(_error ? _error.message : null)
            }
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            setForm(temp)
        },
        onUpdateModal: (_key, _value) => {
            let _modals = {...modals, [_key]:_value}
            setModals(_modals)
        },
        onSubmit: async () => {
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }
    
            //Extract data
            let data2send = onGetSelectedFormData(form, user)
            console.log(data2send)
            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/user/${id}`, data2send)
                const _user = await private_server.get(`/user/${id}`)
                setUser(_user.data.data.user)
                //await actions.onInitModule()
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                //setError(_error ? _error.message : null)   
                onError(_error ? _error.message : null)
            }
            setSending(false)
        }
    }

    const system = {loading, sending}
   

    return {system, form, user, actions, modals}
}

export default useAccount

const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
           
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
         
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
        
        },
        rules: {
            type: 'email',
        }
    },
    mobile: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,     
        },
        rules: {
            type: 'phone_number',
        }
    },
    /* user_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'user_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }

    }, */
    mobile_country_code: {
        value: '52',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'mobile_country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }

    },
    phone_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'phone_number',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    },
}
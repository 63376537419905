

const helperText = 'Este campo debe tener al menos 1 caracter y máximo 255'


export const translations = {
    title:'Información de usuario',
    profile_title:'Mi perfil',
    menu:[{id:1, label:'Cuenta'}, {id:2, label:'Contraseña'}],
    breadcrumbs:{label:'Usuarios', url:'/users'},
    views:{
        account:{
            title:'Cuenta',
            form_title:'Información personal',
            form:{
                first_name:{label:'Nombre (s)', helperText:helperText},
                last_name:{label:'Apellido (s)', helperText:helperText},
                user_type_id:{label:'Tipo de usuario', helperText:'Debe seleccionar una opción'},
                email:{label:'Email', helperText:'Email no válido'},
                mobile:{label:'Teléfono', helperText:'Número de teléfono inválido'},
            },
            delete_button:'Eliminar',
            save_button:'Guardar',
            message_modal:'¿Estás seguro de eliminar a este usuario?',
            profile:{
                change_password:'Cambiar contraseña',
                info:'Información personal'
            }
        },
        password:{
            title:'Cambio de contraseña',
            subtitle:'En esta sección podrás actualizar tu contraseña actual',
            form:{
                password:{label:'Nueva contraseña', helperText:'La contraseña debe contener por lo menos 6 caracteres'},
                previous_password:{label:'Contraseña anterior', helperText:'Campo requerido'},
                confirm_password:{label:'Confirmar contraseña', helperText:'Las contraseñas no coinciden'},
            },
            button:'Actualizar'
        }
    }
    
}

export default translations
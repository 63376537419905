import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, CircularProgress, Collapse, Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { onGetCurrencyValue, onGetDateFormat, onGetFullname, onGetItemFromCatalogs } from '../../../shared/utility'
import SearchBar from '../../../components/Actions/SearchBar/SearchBar'
import ActionBar from '../../../components/Actions/ActionBar'
import { catalogs } from '../../../texts/esp/catalogs'
import { grey } from '@material-ui/core/colors'
import tableAccordionStyles from '../../../styles/tableAccordionStyles'


const useStyles = makeStyles(theme => ({
    paper:{
        border:'none',
        boxShadow:'none',
        background:'transparent'
    },
    cell:{
        border:'none',
        //borderBottom:'3px solid #F8F8F8',
        background:'white',
        color:' #22252F',
        '&:first-child':{
            borderRadius:'10px 0px 0px 10px',
        },
        '&:last-child':{
            borderRadius:'0px 10px 10px 0px',
        }        
    },
    header:{
        fontSize:16,
        color:'#A3A3A3', 
        fontWeight:600
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        border:'none',   
        paddingTop:16,
        paddingBottom:16,
        background:'#F8F8F8'
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16,
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100,   
        borderCollapse: 'separate',
        borderSpacing: '0px 3px'
    },
    table_body:{

    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    empty:{
        width:'100%',
        height:'100%',
    },
    empty_container:{
        marginTop:100,
        marginBottom:40,
        borderRadius:'50%',
        background:'white',
        //boxShadow:'0px 10px 20px rgba(0, 0, 0, 0.02)',
        //border:'3px solid #E8EBF3',
        border:`3px solid ${theme.palette.primary.dark}`,
        width:140,
        height:140,
        padding:32,
        boxSizing:'border-box'
    },
    add_button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    delete_button:{
        border:'1px solid rgba(195, 195, 195, 0.27)',
        color:'#A9A9A9'
    },
    row_table:{
        //minWidth:1100,   
        borderCollapse: 'separate',
        borderSpacing: '0px 10px'
    },
    accordion_cell:{
        background:'#FDFDFD',
        border:'none',
        paddingTop:0,
        paddingBottom:0,
        borderRadius:'0px 0px 10px 10px'
        //color:' #22252F',
        /* '&:first-child':{
            borderRadius:'10px 0px 0px 10px',
        },
        '&:last-child':{
            borderRadius:'0px 10px 10px 0px',
        }  */
    },
    accordion_header:{
        fontWeight:600,
        color:'#7A7A7A',
        //borderBottom:'1px solid #E1E1E1'
    },
    accordion_table_cell:{
        paddingTop:20,
        border:'none',
        verticalAlign:'top'
    }

}))


const SimpleTable = props => {

    const classes = tableAccordionStyles()
    const {data, task_type, filter, content, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, year, onRemoveItem, onChangeSearch, onAddRegister, onDeleteItem, onDownloadInvoice} = props
    const header = content.header
    //console.log(data)

    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)

    const onOpenRow = (_id) => {
        setSelected(_id)
        setOpen(!open) 
    }

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} alignItems='center' justifyContent='space-between' > 
                    <Grid item><SearchBar value={filter.query} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/></Grid>   
                    <Grid item><IconButton className={classes.add_button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid>      
                </Grid>
            </div>
            {loading ? (
                <div><LinearProgress /></div>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell className={classes.cellHeader} key={item.id.toString()}>
                                                    <Typography className={classes.header} align={item.id === 6 ? 'center' : 'left'}  >{item.label}</Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody className={classes.table_body} >
                                {data.map((item,key) => {
                                    const {id_tank_sale, date, service_date, client, seller, cylinder_tare, price, payment_type_id, payment_type, payment_ticket_number, paid, delivered,
                                        cylinder_tag, tank_type_id, valve_tag, truck, filled_kilograms, requested_kilograms, gas_price, filled_price} = item

                                    return(
                                        <React.Fragment key={key.toString()}>
                                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={classes.row} >
                                                <TableCell className={classes.cell}>
                                                    <IconButton aria-label="expand row" size="small" >
                                                        {open && key === selected ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell className={classes.cell} onClick={() => onOpenRow(key)}><Typography >{id_tank_sale}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onOpenRow(key)}><Typography>{onGetDateFormat(date)}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onOpenRow(key)} style={{minWidth:200}}><Typography >{client}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onOpenRow(key)} style={{minWidth:200}}><Typography >{seller}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onOpenRow(key)}><Typography >{cylinder_tare}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onOpenRow(key)}><Typography >{onGetCurrencyValue(price)}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onOpenRow(key)} style={{minWidth:150}}><Typography >{payment_type ?? '-'}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onOpenRow(key)} style={{minWidth:150}} ><Typography >{payment_ticket_number}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onOpenRow(key)}><Checkbox color='primary' checked={paid} /></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onOpenRow(key)}><Checkbox color='primary' checked={delivered} /></TableCell>
                                                <TableCell className={classes.cell}  >
                                                    <IconButton disabled={!paid || !delivered} color='primary' onClick={() => onDownloadInvoice(item)}><Icon>cloud_download</Icon></IconButton>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className={classes.row}  >
                                                <TableCell className={classes.accordion_cell} colSpan={12}
                                                //style={{borderBottom: open ? '10px solid #F8F8F8' : 'none' }} 
                                                >
                                                <Collapse in={open && key === selected} timeout="auto" unmountOnExit>
                                                    <Box sx={{ margin:'20px 0px 40px' }} >
                                                    <Typography color='primary' style={{marginBottom:20}} gutterBottom component="div">
                                                        {content.details.title}
                                                    </Typography>
                                                    <Table size="small" aria-label="purchases" >
                                                        <TableHead>
                                                        <TableRow>
                                                            {content.details.header.map(item=>{
                                                                return(
                                                                    <TableCell  key={item.id.toString()} style={{borderBottom:'1px solid #E1E1E1'}} >
                                                                        <Typography className={classes.accordion_header}  >{item.label}</Typography>
                                                                    </TableCell>
                                                                )
                                                            })}
                                                        </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableCell className={classes.accordion_table_cell} >
                                                                <DataDisplayer label={content.details.body.type} data={onGetItemFromCatalogs(catalogs.tank_types, tank_type_id )} />
                                                                <DataDisplayer label={content.details.body.cylinder_tag} data={cylinder_tag} />
                                                                <DataDisplayer label={content.details.body.valve_tag} data={valve_tag} />
                                                                <DataDisplayer label={content.details.body.tare} data={cylinder_tare} />
                                                            </TableCell>
                                                            <TableCell className={classes.accordion_table_cell} >
                                                                <DataDisplayer label='Nombre' data={truck} />
                                                            </TableCell>
                                                            <TableCell className={classes.accordion_table_cell} >
                                                                <Grid container>
                                                                    <Grid  xs={12}>
                                                                        <DataDisplayer label={content.details.body.price} data={onGetCurrencyValue(price)} />
                                                                        {/* <DataDisplayer label={content.details.body.service_date} data={onGetDateFormat(service_date)} /> */}
                                                                        <DataDisplayer label={content.details.body.operation_date} data={onGetDateFormat(date)} />
                                                                    </Grid>
                                                                    {/* <Grid md={6} xs={12}>
                                                                        <DataDisplayer label={content.details.body.requested_kg} data={requested_kilograms} />
                                                                        <DataDisplayer label={content.details.body.filled_kg}data={filled_kilograms} />
                                                                        <DataDisplayer label={content.details.body.price} data={onGetCurrencyValue(filled_price)} />
                                                                    </Grid> */}
                                                                </Grid>
                                                            </TableCell>
                                                        </TableBody>
                                                    </Table>
                                                    </Box>
                                                </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>                
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.empty_container}>
                                <img src={content.empty} alt='' className={classes.empty}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>{content.nodata}</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable

const DataDisplayer = ({label, data}) => {
    return(
        <Grid container spacing={1} alignItems='center' style={{margin:'12px 0px'}} >
            <Grid item md={6}><Typography variant='body2' style={{color:'#A5A5A5'}}>{label}</Typography></Grid>
            <Grid item md={6}><Typography variant='body2' style={{color:'#22252F'}}>{data}</Typography></Grid>
        </Grid>
    )
}
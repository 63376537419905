import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from 'axios'
import FileSaver from 'file-saver'
import { CONFIG_SETTINGS, private_server } from "../../../../../config"
import queryString from 'query-string'
import {  onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"

const useTanks = ({ content, catalogs, onError}) => {

    const {id} = useParams()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})

    const [tanks, setTanks] = useState([])
    const [selected, setSelected] = useState(null)

    const [modals, setModals] = useState({
        delete:false,
        add:false,
        edit:false,
        upload_evidence:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const onGetParams = () => {

        const {limit, offset} = tableFilter
        const params2send = {limit:limit, offset:limit*offset,  order:1}  

        let filterArgs = [
            {"field":"client_id","operator":"=", "value":id},
        ]

        /* if(query){
            filterArgs.push({"field":"first_name","operator":"LIKE", "value":`%${query}%`})
        } */

        let finalArgs = {"AND":filterArgs}
        let params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
    
        return params
    }
  

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const request = await private_server.get(`/clienttank/all${_params}`)
                const req_tanksales = await private_server.get(`/tanksale/all${_params}`)
                //console.log(req_tanksales.data.data.tank_sales)
                console.log(request)
                const temp = request.data.data
                const _tanks_sales = req_tanksales.data.data.tank_sales
                let data = []
                temp.client_tanks.forEach(el => {
                    _tanks_sales.forEach(tank => {
                        if(el.id_client_tank === tank.client_tank_id) {
                            //console.log(el.id_client_tank, tank.client_tank_id)
                            el ={...el, ...tank}
                            data.push(el)
                        }
                    })
                });
                //console.log(temp.client_tanks)
                //console.log([...temp.client_tanks, ..._tanks_sales])
                //console.log(data)
                setTotal(temp.count)
                setTanks(data)
                setLoading(false)       
            } catch (error) {
                setLoading(false)
                console.log(error)
                const _error = onGetErrorMessage(error)
                onError(_error ? _error.message : null)
                //setError(_error ? _error.message : null)
            }
        },
        onUpdateModal: (_key, _value) => {
            let _modals = {...modals, [_key]:_value}
            setModals(_modals)
        },
        onClickActionButton: (type, data) => {
            setSelected(data)
            if(type === 'delete') actions.onUpdateModal('delete', true)
            if(type === 'upload') actions.onUpdateModal('upload_evidence', true)
            if(type === 'download') actions.onDownloadImage(data)
        },
        onSelectedItem: (data) =>{
            setSelected(data)
            actions.onUpdateModal('edit', true)
        },
        onDownloadImage: async(data) => {
            try {
                const req_url = await private_server.get(`/tanksale/${data.id_tank_sale}/file?name=document`)
                //const url = `${CONFIG_SETTINGS.S3_SERVER_URL}${data.document}`
                delete axios.defaults.headers.common["Authorization"]
                const response = await axios.get(req_url.data.data.url, {responseType: 'blob'})   
                FileSaver.saveAs(response.data, `evidencia`)
            }catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                onError(_error ? _error.message : null)
            }
        },
        
    }

    const system = {loading, sending}
   

    return {system, actions, modals, total, tableFilter, tanks, selected}
}

export default useTanks

const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
           
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
         
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
        
        },
        rules: {
            type: 'email',
        }
    },
    mobile: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,     
        },
        rules: {
            type: 'phone_number',
        }
    },
    user_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'user_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }

    },
    mobile_country_code: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'mobile_country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }

    },
    phone_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'phone_number',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    },
}
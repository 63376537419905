import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../../shared/utility'
import InputForm from '../../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../../../components/Actions/RoundedButton'
import InputSelectForm from '../../../../../../components/Forms/InputSelectForm'
import { private_server, USER_TYPES } from '../../../../../../config'
import { modalStyles } from '../../../../../../styles/modalStyles'
import { onInitForm } from '../../../../../../shared/utility'


const EditModal = props => {

    const { open, onClose, content, catalogs, onCompleted, id, origin } = props

    const classes = modalStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')


    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }else{
            let _form = onInitForm(form, origin, content.form)
            _form.tank_type_id.options = catalogs.tank_types
            setForm(_form)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    //por mientras
    const onGetRandomID = () => {
        let result = ''
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        for (let i = 0; i < 10; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result
    }

    const onGetID = (_key) => {
        let temp = { ...form }
        temp[_key].value = onGetRandomID()
        temp[_key].isValid = true
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            console.log(errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, origin)
        //data2send.client_id = id
       

        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            console.log(data2send)
            await private_server.patch(`/clienttank/${origin.id_client_tank}`, data2send)
            await onCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)    
        }
        setLoading(false)

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='xs'>
            <div >
                <Typography variant='h6' className={classes.title}>{content.edit.title}</Typography>
                <Typography variant='body1' >{content.subtitle}</Typography>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}  >
                            <InputSelectForm data={form.tank_type_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}  >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}  >
                            <InputForm data={form.location} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}  >
                            <InputForm data={form.cylinder_tag} onChange={onChange} />
                            {/* <Grid container spacing={2} alignItems='center' >
                                <Grid item md={9}><InputForm data={form.cylinder_tag} onChange={onChange} /></Grid>
                                <Grid item md={3}><Button color='primary' onClick={()=>onGetID('cylinder_tag')} >{content.form_button}</Button></Grid>
                            </Grid> */}
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.valve_tag} onChange={onChange} />
                            {/* <Grid container spacing={2} alignItems='center' >
                                <Grid item md={9}><InputForm data={form.valve_tag} onChange={onChange} /></Grid>
                                <Grid item md={3}><Button color='primary' onClick={()=>onGetID('valve_tag')} >{content.form_button}</Button></Grid>
                            </Grid> */}               
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.cylinder_tare} onChange={onChange} endAdornment={<Typography style={{color:'#999999'}} >kg</Typography>} />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.button_container}>
                    <RoundedButton color='primary' onClick={onSubmit} loading={loading}>
                        {content.edit.button}
                    </RoundedButton>
                </div>
                <Typography color='error' align='center'>{error}</Typography>
            </div>
        </SimpleModal>
    )
}

export default EditModal


const formData = {
    cylinder_tag: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'cylinder_tag',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    valve_tag: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'valve_tag',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    location: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'location',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    cylinder_tare: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'cylinder_tare',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    }, 
    tank_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'tank_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
}
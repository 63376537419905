import { Grid, Icon, InputBase, makeStyles, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import AvatarPicture from "../../../../components/Structure/DisplayData/AvatarPicture"
import { private_server } from "../../../../config"
import { onGetErrorMessage, onGetFullname, updateAuthorizationHeader } from "../../../../shared/utility"


const ClientModal = ({open, onClose, content, onSetClient, tank_type_id}) => {

    const classes = useStyles()

    const [users, setUsers] = useState([])
    const [value, setValue] = useState(null)
    const [error, setError] = useState(null)

    useEffect(async() => {
        if(open){
            updateAuthorizationHeader(private_server)
            const _users = await private_server.get(`/user/all?limit=7`)
            setUsers(_users.data.data.users)
        }
    }, [open])

    const onSearchUsers = async(data) =>{

        //console.log(data.target.value)
        setValue(data.target.value)
        let params = '?limit=7'

        if(value !== null && value !== ''){
            const filtersArgs = [
                {"field":"first_name","operator":"LIKE", "value":`%${data.target.value}%`},
                //{"field":"last_name","operator":"=", "value":`%${value}%`},
            ]
    
            const finalArgs = {"OR":filtersArgs}
    
            params = `?filter=${encodeURIComponent(JSON.stringify(finalArgs))}&limit=7`
        }

        

        updateAuthorizationHeader(private_server)
        const _users = await private_server.get(`/user/all${params}`)
        setUsers(_users.data.data.users)
    }

    const onSubmit = async(_data) =>{
        try {
            updateAuthorizationHeader(private_server)
            const data2send={
                client_id:_data.id_user,
                tank_type_id:tank_type_id
            }
            const new_client = await private_server.post('/clienttank', data2send)
            console.log(new_client)
            onSetClient(new_client.data.data.client_tank)
            onClose()
        } catch (error) {
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
        }
    }

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs'>
            <div style={{height:720}}>
                <Typography variant='h6'  style={{ fontWeight: 600 }}>{content.title}</Typography>
                <div className={classes.search_bar} >
                    <Grid container alignItems='center' spacing={1}> 
                        <Grid item xs>
                            <InputBase value={value} onChange={onSearchUsers}  placeholder={content.placeholder} 
                                 style={{ width:'100%'}} />
                        </Grid>
                        <Grid item>
                            <Icon style={{color:'#555555'}} fontSize='default'>search</Icon>
                        </Grid>
                    </Grid>
                </div>
                <Grid container direction="column" justifyContent="center" spacing={2} >
                    {users.length !== 0 ? users.map(item =>
                        <Grid item xs={12} key={item.id_user}  >
                            <div onClick={()=>onSubmit(item)}>
                                <Grid container spacing={3} alignItems='center' className={classes.user_container} >
                                    <Grid item>
                                        <AvatarPicture src={item.image} size={54} />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography style={{fontSize:'1.2rem', fontWeight:600}}>{onGetFullname(item.first_name, item.last_name)}</Typography>           
                                        <Typography style={{color:item.user_status_id === 1 ? '#3CB242' : '#F8D53B'}} >{item.user_status}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    ): <Grid item ><Typography variant="subtitle1">{content.nousers}</Typography></Grid>}
                </Grid>
                
            </div>
        </SimpleModal>
    )
}

export default ClientModal

const useStyles = makeStyles(theme => ({
    search_bar:{
        background:'#F8F8F8',
        borderRadius:12,
        padding:'10px 14px',
        boxSizing:'border-box',
        margin:'30px 0px'
    },
    user_container:{
        //margin:'12px 0px',
        borderBottom:'1px solid #EBEBEB',
        //padding:'12px 0px'
        marginTop:0,
        cursor:'pointer'
    }
    
    
}))
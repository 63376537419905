import { useState, useEffect } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"
import { private_server, public_server } from "../../../../../config"
import queryString from 'query-string'
import { useParams } from "react-router-dom"


const useTransactions = ({history}) => {

    const {id} = useParams()
    
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    //gas
    const [gas, setGas] = useState([])
    const [total_gas, setTotalGas] = useState(0)
    const [tableFilter_gas, setTableFilterGas] = useState({limit:10,offset:0})
    const [filter_gas, setFilterGas] = useState({query:''})
    
    //tanks
    const [tanks, setTanks] = useState([])
    const [total_tanks, setTotalTanks] = useState(0)
    const [tableFilter_tanks, setTableFilterTanks] = useState({limit:10,offset:0})
    const [filter_tanks, setFilterTanks] = useState({query:''})


    const [modals, setModals] = useState({
        add_register: false,
        edit_register:false,
        delete_register:false
    })


    


    useEffect(()=>{window.scrollTo(0,0)},[])

    useEffect(() => {
        actions.onInitModuleGas()
    }, [tableFilter_gas])

    useEffect(() => {
        actions.onInitModuleTanks()
    }, [tableFilter_tanks])

    const onGetParamsGas = () => {
        const {limit, offset} = tableFilter_gas
        const {query} = filter_gas

        let andFiltersArgs = [
            {"field":"client_id","operator":"=", "value":id},
        ]
     
        const param2send = {limit:limit, offset:limit*offset, order_by:'["id_gas_sale"]', order:1}  
        
        if(query){
            andFiltersArgs.push({"field":"first_name","operator":"LIKE", "value":`%${query}%`})
        }

        let finalArgs = {"AND": andFiltersArgs}
        let params = `?${queryString.stringify(param2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`

        return params
    }

    const onGetParamsTanks = () => {
        const {limit, offset} = tableFilter_tanks
        const {query} = filter_tanks

        let andFiltersArgs = [
            {"field":"client_id","operator":"=", "value":id},
        ]
     
        const param2send = {limit:limit, offset:limit*offset}  
        
        if(query){
            andFiltersArgs.push({"field":"first_name","operator":"LIKE", "value":`%${query}%`})
        }

        let finalArgs = {"AND": andFiltersArgs}
        let params = `?${queryString.stringify(param2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`

        return params
    }
    
    const actions = {
        onInitModuleGas: async() => {
            setLoading(true)
            try {
                const _params = onGetParamsGas()
                console.log(_params)
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/gassale/all${_params}`)
                const temp = users_request.data.data
                setTotalGas(temp.count)
                setGas(temp.gas_sales)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            setLoading(false)
        },
        onChangeSearchGas:(data) => {
            //console.log(data)
            let temp = {...filter_gas}
            temp.query = data
            setFilterGas(temp)
        },
        onUpdateTableFilterGas: (data) => setTableFilterGas(data),
        onChangeFilterGas: (data) => setFilterGas(data),
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onInitModuleTanks: async() => {
            setLoading(true)
            try {
                const _params = onGetParamsTanks()
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/tanksale/all${_params}`)
                const temp = users_request.data.data
                console.log(temp)
                setTotalTanks(temp.count)
                setTanks(temp.tank_sales)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            setLoading(false)
        },
        onChangeSearchTanks:(data) => {
            //console.log(data)
            let temp = {...filter_tanks}
            temp.query = data
            setFilterTanks(temp)
        },
        onUpdateTableFilterTanks: (data) => setTableFilterTanks(data),
        onChangeFilterTanks: (data) => setFilterTanks(data),
        
    }

    const system = {loading, sending, error}
    const view_data = {gas, total_gas, tableFilter_gas, filter_gas, tanks, total_tanks, tableFilter_tanks, filter_tanks}

    return {system, actions, view_data, modals}
}

export default useTransactions
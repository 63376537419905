import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { isFormValid, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm } from "../../../../../shared/utility"
import { private_server } from "../../../../../config"
import {  onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"

const usePassword = ({ content, catalogs, onError}) => {

    const {id} = useParams()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    //const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    const [values, setValues] = useState({
        previous_password: false,
        new_password: false,
      });

    useEffect(() => {
        actions.onInitModule()
    }, [])

  

    const actions = {
        onInitModule: async() => {
            let _form = onInitForm(JSON.parse(JSON.stringify(formData)), null, content.form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            console.log(data)
            let temp = { ...form }
            const id = data.config.id
            console.log(id)
            temp[id] = { ...data }
            if(id === 'password') temp['confirm_password'].value2 = temp[id].value
            setForm(temp)
        },
        onShowPassword: (key) => {
            //console.log(values[key])
            setValues({...values, [key]: !values[key]})
            let temp = { ...form }

            if(temp[key] && !values[key]){
                temp[key].config.type = 'text'
            }else{
                temp[key].config.type = 'password'
            }
            setForm(temp)
        }, 
        onSubmit: async () => {
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }
    
            //Extract data
            let data2send = onGetFormData(form)
            console.log(data2send)
            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/user/${id}`, data2send)
                
                await actions.onInitModule()
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                //setError(_error ? _error.message : null)   
                onError(_error ? _error.message : null)
            }
            setSending(false) 
        }
    }

    const system = {loading, sending}
   

    return {system, form, actions, values}
}

export default usePassword

const formData = {
    password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
         
        },
        rules: {
            type: 'distance',
            min: 6, max: 20
        }
    },
    confirm_password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'confirm_password',
            type: 'password',
            fullWidth: true,
        
        },
        rules: {
            type: 'equals',
        }
    },
    
}
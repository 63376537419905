import logo from '../../assets/logo.png'

const translations = {
    title:'¡Bienvenido!',
    forgotten_password:'¿Olvidaste tu contraseña?',
    logo:logo,
    button:'Acceder',
    form:{
        email:{placeholder:'Correo electrónico', label:'Correo electrónico', helperText:'Campo requerido'},
        password:{placeholder:'Contraseña', label:'Contraseña', helperText:'Campo requerido'},
    },

}

export default translations
import { Grid, makeStyles, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import InputForm from '../../../../../components/Forms/InputForm'
import { onGetErrorMessage, onInitForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import queryString from 'query-string'
import { private_server } from "../../../../../config"

const NewSale = ({ content, onGoToStep, onSetTank, onSetClient}) => {

    const classes = useStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    

    useEffect(() => {
        let _form = onInitForm(formData, null, content.form)
        setForm(_form)
    }, [])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async() => {

        const filtersArgs = [
            {"field":"cylinder_tag","operator":"=", "value":form.cylinder_tag.value},
            {"field":"valve_tag","operator":"=", "value":form.valve_tag.value}
        ]

        const finalArgs = {"AND":filtersArgs}

        const params = `?filter=${encodeURIComponent(JSON.stringify(finalArgs))}`

        try {
            updateAuthorizationHeader(private_server)
            const tanks = await private_server.get(`/tanksale/all${params}`)
            const temp = tanks.data.data.tank_sales
            //console.log(temp)
            if(temp.length !== 0){
                if (temp[0].client_id !== null && temp[0].client_id !== ''){
                    const _client = await private_server.get(`/user/${temp[0].client_id}`)
                    onSetClient(_client.data.data.user)
                }
                onSetTank(temp[0])
                onGoToStep(2)
            }else{
                setError('No se encontraron tanques')
            }
        } catch (error) {
            console.log(error)
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
        }
    }

    return(
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}  >
                    <InputForm data={form.cylinder_tag} onChange={onChange} />
                </Grid>
                <Grid item xs={12}  >
                    <InputForm data={form.valve_tag} onChange={onChange} />
                </Grid>      
            </Grid>
            <div className={classes.button_container}>
                <RoundedButton color='primary' fullWidth  onClick={onSubmit} loading={loading}>
                    {content.button}
                </RoundedButton>
            </div> 
            {error ? <Typography style={{marginTop:12}} color='error' align='center'>{error}</Typography> : null}
        </div>
    )
}

export default NewSale

const useStyles = makeStyles(theme => ({
    title_container:{
        padding:'20px 0px'
    },
    button_container:{
        textAlign:'center',
        marginTop:40,
    },
    
}))

const formData = {
    cylinder_tag: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'cylinder_tag',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    valve_tag: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'valve_tag',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}
import React from 'react';
import { alpha, Button, CircularProgress, makeStyles } from '@material-ui/core';
import cx from 'classnames'
import { red } from '@material-ui/core/colors';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.primary.main,
        fontFamily:theme.typography.fontFamily,
        textTransform:'none',
        padding:'12px 32px',
        borderRadius:20,
        color:'white',
        fontSize:'1rem',
        '&:hover':{
            background:theme.palette.primary.dark,
        },
        '&:disabled':{
            background:grey[300],
            color:grey[500],
            cursor:'not-allowed'
        }
    },
    root_small:{
        padding:'8px 20px',
        fontSize:'1rem',
    },
    progress:{
        color:'white',
        marginLeft:8
    },
    primary:{
        background:theme.palette.primary.main,
        '&:hover':{background:theme.palette.primary.dark}
    },
    secondary:{
        background:theme.palette.secondary.main,
        '&:hover':{background:theme.palette.secondary.dark}
    },
    red:{
        background:red[700],
        '&:hover':{background:red[900]}
    },
    grey:{
        background:grey[300],
        '&:hover':{background:grey[400]},
        color:grey[900],
    },
    white:{
        color:theme.palette.primary.main,
        border:`1px solid #D8D8D8`,
        background:'white',
        '&:hover':{background:alpha('#000',0.025)}
    }
}))

const RoundedButton = ({loading, children, color, onClick, fullWidth, disabled, size}) => {

    const classes = useStyles()

    return ( 
        <Button onClick={!loading ? onClick : undefined} fullWidth={fullWidth} disabled={disabled}
        className={cx({
            [classes.root]:true, 
            [classes.root_small]:size==='small',
            [classes.secondary]: color==='secondary',
            [classes.red]: color==='red',
            [classes.white]: color==='white',
            [classes.grey]: color==='grey'
        })}>
            {children}
            {loading ? (<CircularProgress size={24} className={classes.progress}/>) : null}
        </Button>
     );
}
 
export default RoundedButton;
import React from 'react'
import { Grid, TextField, Typography, withStyles } from '@material-ui/core'
import { ruleValidation } from './customFunctions'

const CssTextField = withStyles({
    root: {
        //paddingTop:6,
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:12,
        '& fieldset': {
          //borderColor: 'red',
        },
        '&:hover fieldset': {
          //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          //borderColor: 'green',
        },
      },
    },
  })(TextField);

const InputInlineForm = props => {

    const {data, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown, onKeyPress} = props

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited} = data

    const isError = isVisited && !isValid
    let interConfig = {...config}
    delete interConfig.label
    delete interConfig.placeholder
    if(!isError) interConfig.helperText = ''


    return(
        <div>
          <Grid container spacing={2} alignItems='center' >
            <Grid item sm={3} xs={12}>
              <Typography variant='subtitle1' color='textSecondary' >{config.label}</Typography>
            </Grid>
            <Grid item sm={9} xs={12}>
              <CssTextField  value={value} variant='outlined' onKeyPress={onKeyPress}
              error={isError} {...interConfig} onChange={onInnerChange} 
              InputProps={
                  {
                      startAdornment:startAdornment ? startAdornment : undefined,
                      endAdornment:endAdornment ? endAdornment : undefined,
                      onKeyUp:onKeyUp ? onKeyUp : undefined,
                      onKeyDown:onKeyDown ? onKeyDown : undefined,
                  }}
              />
            </Grid>
          </Grid>    
        </div>
    )
}

export default InputInlineForm
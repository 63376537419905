import { useState, useEffect } from "react"
import { isValidDocument, onDownloadFile, onGetErrorMessage, updateAuthorizationHeader } from "../../shared/utility"
import { private_server } from "../../config"
import queryString from 'query-string'
import { USER_TYPES } from "../../shared/enums"

const useGasSalesView = ({history}) => {
    
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})
    const [filter, setFilter] = useState({query:''})
    const [selectedItem, setSelectedItem] = useState(null)
    const [modals, setModals] = useState({
        add_register: false,
        edit_register:false,
        delete_register:false
    })
    const [person, setPerson] = useState(null)

    useEffect(()=>{window.scrollTo(0,0)},[])

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const onGetParams = () => {
        const {limit, offset} = tableFilter
        const {query} = filter

        let andFiltersArgs = [
            {"field":"user_type_id","operator":"=", "value":USER_TYPES.ADMIN},
            {"field":"user_type_id","operator":"=", "value":USER_TYPES.OPERATOR}
        ]
     
        let prefinalArgs = {"OR":andFiltersArgs}
        const param2send = {limit:limit, offset:limit*offset, order_by:'["id_gas_sale"]', order:1}  
        let params = `?${queryString.stringify(param2send)}`
        

        let finalArgs = {}
    
        if(query){
            //andFiltersArgs.push({"field":"first_name","operator":"LIKE", "value":`%${query}%`})
            finalArgs = {"AND":[
                {"field":"client","operator":"LIKE", "value":`%${query}%`},
            ]}
            params = `?${queryString.stringify(param2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
        }
 
        

        return params
    }
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/gassale/all${_params}`)
                const temp = users_request.data.data
                setTotal(temp.count)
                setData(temp.gas_sales)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            setLoading(false)
        },
        onDownloadInvoice: async(data) => {
            if(sending) return
            console.log('Invoice data', data)
            const isvalid = isValidDocument(data?.invoice_url)
            setSending(true)
            if(isvalid){
                await onDownloadFile(data?.invoice_url, `invoice-${data?.id_gas_sale}`)
            }
            setSending(false)

        },
        onDownloadInvoice: async(data) => {
            if(sending) return
            console.log('Invoice data', data)
            const isvalid = isValidDocument(data?.invoice_url)
            setSending(true)
            if(isvalid){
                onDownloadFile(data?.invoice_url, `invoice-${data?.id_gas_sale}`)
            }else{
                console.log('We need to request again the creation on file')
                try {
                    updateAuthorizationHeader(private_server)
                    const response = await private_server.post(`/gassale/${data?.id_gas_sale}/invoice`)
                    console.log(response.data.data)
                    await actions.onInitModule()
                } catch (error) {
                    console.log(error?.response)
                    setError(error?.response?.data?.error?.message || 'Sorry, we could not process your request, try again')
                    
                }
            }
            setSending(false)

        },
        onUpdateUsers: async() => {
            setSending(true)
            try {
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/user/all${_params}`)
                const temp = users_request.data.data
                setTotal(temp.count)
                setData(temp.users)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            setSending(false)
        },
        onChangeSearch:(data) => {
            //console.log(data)
            let temp = {...filter}
            temp.query = data
            setFilter(temp)
        },
        onDeleteSelected:(data) => {
            console.log(data)
            setSelectedItem(data)
            actions.onUpdateModalStatus('delete_register', true)
        },
        onSelectItem: (data) =>{
            const {id_user} = data
            history.push(`users/${id_user}`)
        },
        onChangeUserStatuses:async(status, new_value, _id) => {
            let data2send = {}
            if(status === 'enabled') data2send={active: new_value} 
            //setLoading(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/user/${_id}`, data2send)
                actions.onInitModule()
            } catch (error) {
                console.log(error)
            }
            //setLoading(false)
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onChangeFilter: (data) => setFilter(data)
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, filter, selectedItem}

    return {system, actions, view_data, modals}
}

export default useGasSalesView
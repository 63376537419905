import React from 'react'
import { makeStyles, Breadcrumbs, Link, Icon, Grid, CircularProgress, IconButton, Snackbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { blue, blueGrey, green } from '@material-ui/core/colors'
import { withRouter } from 'react-router-dom'
import ErrorModal from '../../Modals/ErrorModal'
import SectionContainer from './SectionContainer'
import SmallNavigation from '../Navigation/SmallNavigation'

const useStyles = makeStyles(theme => ({
    root:{
        marginTop:'76px',
        boxSizing:'border-box', 
        minHeight:'calc(100vh - 76px)',
        //padding:40,   
        paddingLeft:16,
        [theme.breakpoints.down('sm')]:{
            paddingLeft:0
        }
    },
    container:{
        paddingTop:'40px',
        //width:1100,
        //margin:'auto',
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]:{
            //paddingTop:16
        }
    },
    link:{
        color:blueGrey[500],
        fontWeight:400,
        '&:hover':{
            cursor:'pointer'
        }
    },
    selectedLink:{
        color:blueGrey[700],
        fontWeight:500
    },
    icon:{
        color:blueGrey[500],
        paddingTop:4,
        
    },
    loading:{
        height:`calc(100vh - 400px)`
    },
    success:{
        background:green[700]
    },
    general_title:{
        color:blueGrey[900],
        fontSize:28
    },
    addIcon:{
        background:theme.palette.primary.main,
        borderRadius:'50%',
        color:'white',
        width:36,
        height:36,
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    sending:{
        background:blue[700],
        borderRadius:12
    }
}))

const Page = props => {

    const classes = useStyles()
    const {children, loading, error, onCloseError, success, onCloseSuccess, title, sending, 
        setReturn, history, nopadding, menu, selected, onChange, onAdd} = props

    const onSelectedLink = (url) => {
        if(url) props.history.push(url)
    }



    let contentView = children

    if(loading){
        contentView = (
            <Grid container justifyContent='center'>
                <Grid item>
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                    
                </Grid>
            </Grid>
        )
    }

    const successContent = <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} ContentProps={{className:classes.success}} open={Boolean(success)} onClose={onCloseSuccess}  autoHideDuration={1500} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>done</Icon></Grid>
        <Grid item><Typography style={{color:'white'}}>{success}</Typography></Grid>
    </Grid>}/>

    const errorModal = <ErrorModal open={Boolean(error)} message={error} onClose={onCloseError}/>
    let menuContent = null
    if(menu){
      menuContent = (
        <div className={classes.menu}>
            <Grid container justify='flex-start'>
                <Grid item>
                    <SmallNavigation
                        selected={selected}
                        data={menu}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
        </div>
      )
    }

    const loadingContent = (
        <Grid container justify='center' alignItems='center' direction='column' spacing={1}>
            <Grid item>
                <CircularProgress size={32}/>
            </Grid>
            <Grid item>
                <Typography>Cargando...</Typography>
            </Grid>
        </Grid>
    )

    const sendingContent = <Snackbar  ContentProps={{className:classes.sending}} open={Boolean(sending)} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><CircularProgress size={18} style={{color:'white'}}/></Grid>
        <Grid item><Typography style={{color:'white'}}>Enviando...</Typography></Grid>
    </Grid>}  anchorOrigin={{vertical:'bottom', horizontal:'left'}}/>

    return(
        <div className={classes.root} style={nopadding ? {marginTop:0} : undefined}>
            <Grid container alignItems='center' spacing={2}>
                {setReturn ? (
                    <Grid item><IconButton onClick={() => history.goBack()}><Icon fontSize='large'>navigate_before</Icon></IconButton></Grid>
                ) : null}
                <Grid item><Typography variant='h5' color='primary' className={classes.general_title}>{title}</Typography></Grid>
                {onAdd ? <Grid item><IconButton className={classes.addIcon} color='primary' onClick={onAdd}><Icon >add</Icon></IconButton></Grid> : null}
            </Grid>
            {menuContent}
            <div className={classes.container} style={nopadding ? {paddingTop:0} : undefined}>
                {sendingContent}
                {successContent}
                {errorModal}
                {loading ? loadingContent : null}
                {!loading && children}
            </div> 
        </div>
    )
}

export default withRouter(Page)
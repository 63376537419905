import React from 'react'
import { Grid, MenuItem, TextField, Typography, withStyles } from '@material-ui/core'
import { ruleValidation } from './customFunctions'

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:12,
        '& fieldset': {
          //borderColor: 'red',
        },
        '&:hover fieldset': {
          //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          //borderColor: 'green',
        },
      },
    },
  })(TextField);

const SelectInlineForm = props => {

    const {data, onChange, startAdornment, endAdornment} = props

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited, options} = data

    const isError = isVisited && !isValid
    let interConfig = {...config}
    delete interConfig.label
    delete interConfig.placeholder
    if(!isError) interConfig.helperText = ''

    const _options = options ? options : []

    return(
        <div>
            <Grid container spacing={2} alignItems='center' >
              <Grid item sm={3} xs={12}>
                <Typography variant='subtitle1' color='textSecondary' >{config.label}</Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <CssTextField  value={value} variant='outlined' 
                error={isError} {...interConfig} select onChange={onInnerChange} 
                InputProps={
                    {
                        startAdornment:startAdornment ? startAdornment : undefined,
                        endAdornment:endAdornment ? endAdornment : undefined,
                    }}
                >
                    {_options.map(item => {
                        return(
                            <MenuItem key={item.value.toString()} value={item.value}>
                                {item.label}
                            </MenuItem>
                        )
                    })}
                </CssTextField>
              </Grid>
          </Grid> 
        </div>
    )
}

export default SelectInlineForm
import React from 'react';
import { Grid, Icon, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const StatusData = ({data, color, bold, variant, text_styles}) => {


    return ( 
        <Grid container alignItems='center' spacing={1} wrap='nowrap'>
            <Grid item><Icon style={{marginBottom:2, fontSize:8, color: color ? color : grey[300]}}>brightness_1</Icon></Grid>
            <Grid item><Typography style={text_styles} variant={bold ? 'subtitle1' : (variant ? variant : 'body1')}>{data}</Typography></Grid>
        </Grid>
    );
}
 
export default StatusData;
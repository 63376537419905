import React, { useState } from 'react';
import { Avatar, fade, Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { grey } from '@material-ui/core/colors';
import { CONFIG_SETTINGS } from '../../../config';
import { isValidDocument } from '../../../shared/utility';
import system_translations from '../../../texts/system_translations';

const AvatarPicture = ({nopicture, src, onChangePicture, size, language}) => {


    const classes = useStyles()
    const content = system_translations[language].general.avatar_picture
    const [hover, setHover] = useState(false)

    let selectedImage = nopicture

    let size_styles = {width:160, height:160}
    if(size) size_styles = {width:size, height:size}

    const isValid = isValidDocument(src)

    if(src){
        selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${src}` : null
    }

    return ( 
        <div onClick={onChangePicture ? onChangePicture : null} className={classes.avatarContainer}
          style={size_styles}  onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
            {hover && onChangePicture ? (<div className={classes.overlay}>
                <div className={classes.picture}>
                    <Grid container direction='column' alignItems='center'>
                        <Grid item>
                            <Icon fontSize='small' style={{color:grey[300], fontSize:24}} >photo_camera</Icon>
                        </Grid>
                        <Grid item>
                            <Typography style={{color:grey[300]}} variant='body2'>{content.edit}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>) : null}
            {isValid && <Avatar src={selectedImage} alt='' className={classes.image} style={size_styles}/>}
            {!isValid && <Avatar className={classes.image} style={size_styles}>D</Avatar>}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    image:{
        width:160,
        height:160,
        background:theme.palette.primary.main,
        fontSize:36
    },
    container:{
        padding:32,
        borderRadius:16,
        border:`1px solid ${grey[300]}`
    },
    avatarContainer:{
        width:160,
        height:160,
        position:'relative'
    },
    overlay:{
        position:'absolute',
        top:0,
        left:0,
        background: fade('#000',0.5),
        zIndex:2,
        width:'100%',
        height:'100%',
        borderRadius:'50%',
        '&:hover':{
            cursor:'pointer'
        }
    },
    picture:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    }
}))

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}

export default connect(mapStateToProps)(AvatarPicture);
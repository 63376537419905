import { Grid, makeStyles, Typography } from "@material-ui/core"
import SimpleCard from "../../../../../components/Structure/Cards/SimpleCard";
import { useParams } from "react-router-dom";
//import DeleteModal from "./modals/DeleteModal";
import useTanks from "./useTanks";
import SimpleTable from "./components/SimpleTableNew";
import DeleteModal from "./modals/DeleteModal";
import AddModal from "./modals/AddModal";
import EditModal from "./modals/EditModal";
import ChangeImageModal from "./modals/ChangeImageModal";
import UploadFileModal from "./modals/UploadFileModal";


const Tanks = ({ history, content, catalogs, onError, language}) => {

    const {id} = useParams()
    const classes = useStyles()

    const { actions, system, modals, tanks, tableFilter, total, selected} = useTanks({history, content, catalogs, onError})

    return(
        <SimpleCard loading={system.loading} >
            <AddModal open={modals.add} onClose={()=>actions.onUpdateModal('add', false)} content={content.modal} catalogs={catalogs} 
                onCompleted={actions.onInitModule} id={id} />
            <EditModal open={modals.edit} onClose={()=>actions.onUpdateModal('edit', false)} content={content.modal} catalogs={catalogs} 
                onCompleted={actions.onInitModule} id={id} origin={selected} />
            <DeleteModal open={modals.delete} onClose={()=>actions.onUpdateModal('delete', false)} onUpdateCompleted={actions.onInitModule} 
                origin={selected} message={content.message_modal} />
            <UploadFileModal open={modals.upload_evidence} id={selected?.id_tank_sale} onCompleted={actions.onInitModule} 
                onClose={() => actions.onUpdateModal('upload_evidence',false)} language={language} /> 
            {/* <ChangeImageModal open={modals.upload_evidence} id={selected?.id_client_tank} onCompleted={actions.onInitModule} 
                onClose={() => actions.onUpdateModal('upload_evidence',false)} /> */}
                <SimpleTable
                    content={content}
                    loading={system.loading}
                    data={tanks} 
                    tableFilter={tableFilter}
                    total={total} 
                    onClickActionButton={actions.onClickActionButton}
                    onAddRegister={() => actions.onUpdateModal('add', true)}
                    onSelectedItem={actions.onSelectedItem}
                    /*
                    onUpdateTableFilter={actions.onUpdateTableFilter}
                    onChangeFilter={actions.onChangeFilter} 
                    onChangeSearch={actions.onChangeSearch}
                    onDeleteItem={actions.onDeleteSelected}
                    onChangeStatuses={actions.onChangeUserStatuses} */
                    />
             
        </SimpleCard>
    )
}



export default Tanks

const useStyles = makeStyles(theme => ({
    title:{
        fontWeight:600,
        fontSize:'1.6rem'
    },
    main_container:{
        padding:'30px 0px',
        borderBottom:'1px solid #E4E4E4',
        marginBottom:40
    },
    name_container:{
        display:'flex',
        width:'100%',
        //background:'red'
    },
    status_container:{
        background:'#F5F5F5',
        padding:'6px 12px',
        borderRadius:4
    },
    form:{
        marginBottom:60
    }
   
    
}))
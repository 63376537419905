import user from '../../assets/icons/user.svg'
import clients from '../../assets/icons/clients.svg'

const helperText = 'Este campo debe tener al menos 1 caracter y máximo 255'

export const translations = {
    title:'Clientes',
    message_modal:'¿Estás seguro de eliminar a este usuario?',
    table:{
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Nombre'},
            {id:3, label:'Email'},
            {id:4, label:'CNPJ/CPF'},
            {id:5, label:'Camión'},
            {id:6, label:'Acciones'},
        ],
        nodata:'No existen clientes registrados',
        empty:clients,
        nouser:user
    },
    modal:{
        subtitle:'Por favor ingresa los datos solicitados',
        add:{
            title:'Agregar cliente',
            button:'Agregar'
        },
        form:{
            password:{label:'Contraseña', helperText:'Debe contener 6 a 20 caracteres'},
            confirm_password:{label:'Confirmar contraseña', helperText:'Las contraseñas no coinciden'},
            first_name:{label:'Nombre (s)', helperText:helperText},
            last_name:{label:'Apellido (s)', helperText:helperText},
            user_type_id:{label:'Tipo de usuario', helperText:'Debe seleccionar una opción'},
            email:{label:'Email', helperText:'Email no válido'},
            mobile:{label:'Teléfono', helperText:'Número de teléfono inválido'},
            social_security_number:{label:'Número de seguridad social', helperText:'Número inválido'},
            mobile_country_code:{label:'Código', helperText:'Requerido'}
        },
    }
    
}

export default translations
import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { onGetFullname } from '../../../../shared/utility'
import SearchBar from '../../../../components/Actions/SearchBar/SearchBar'
import ActionBar from '../../../../components/Actions/ActionBar'
import tableStyles from '../../../../styles/tableStyles'
import UserAvatarName from '../../../../components/Structure/DisplayData/UserAvatarName'




const SimpleTable = props => {

    const classes = tableStyles()
    const {data, filter, content, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onChangeSearch, onDeleteItem, onAddRegister} = props
    const header = content.header
    //console.log(data)

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} alignItems='center' justifyContent='space-between' > 
                    <Grid item><SearchBar value={filter.query} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/></Grid>     
                    <Grid item><IconButton className={classes.add_button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid>    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell className={classes.cellHeader} key={item.id.toString()}>
                                                    <Typography className={classes.header} align={item.id === 6 ? 'center' : 'left'}  >{item.label}</Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody className={classes.table_body} >
                                {data.map((item,key) => {
                                    const {id_user, email, username, image, mobile, first_name, user_type_id, last_name, active, cnpj_cpf,
                                        truck} = item

                                    const fullname = onGetFullname(first_name, last_name)

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography >{id_user}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><UserAvatarName name={fullname} image={image} nopicture={content.nouser}/></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography >{email}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography >{cnpj_cpf}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography >{truck}</Typography></TableCell>
                                            <TableCell className={classes.cell}  style={{minWidth:10}}>
                                                <Grid container spacing={1} wrap='nowrap' justifyContent='center'>
                                                    {/*<Grid item>
                                                        <IconButton size='small' color='primary' ><Icon>edit</Icon></IconButton>
                                                    </Grid>*/}
                                                    <Grid item>
                                                        <IconButton className={classes.delete_button} onClick={() => onDeleteItem(item)} ><Icon>delete</Icon></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.empty_container}>
                                <img src={content.empty} alt='' className={classes.empty}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>{content.nodata}</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import ProfileView from '../views/Profile/ProfileView/ProfileView'
import UserDetailsView from '../views/Users/UserDetailsView/UserDetailsView'
import UsersView from '../views/Users/UsersView/UsersView'
import Clients from '../views/Clients/Clients/Clients'
import ClientView from '../views/Clients/Client/ClientView'
import TankSalesView from '../views/TankSales/TankSalesView'
import GasSalesView from '../views/GasSales/GasSalesView'

const DashboardRouter = props => {
    return(
        <Switch>
            <Route exact path='/gas-sales' component={GasSalesView} />
            <Route exact path='/tank-sales' component={TankSalesView} />
            <Route exact path='/clients/:id' component={ClientView} />
            <Route exact path='/clients' component={Clients} />
            <Route exact path='/users' component={UsersView} />
            <Route exact path='/users/:id' component={UserDetailsView} />
            <Route exact path='/profile' component={ProfileView} />
            <Redirect to='/users' />
        </Switch>
    )
}

export default DashboardRouter


import tank from '../../assets/icons/tank.svg'

export const translations = {
    title:'Ventas de tanques',
    message_modal:'¿Estás seguro de eliminar a este usuario?',
    table:{
        header:[
            {id:0, label:''},
            {id:1, label:'ID'},
            {id:2, label:'Fecha de op.'},
            {id:3, label:'Cliente'},
            {id:4, label:'Vendedor'},
            {id:5, label:'No. de kg'},
            {id:6, label:'Precio'},
            {id:7, label:'Método de pago'},
            {id:8, label:'Ticket'},
            {id:9, label:'¿Pagado?'},
            {id:10, label:'¿Entregado?'},
        ],
        nodata:'No existen ventas registradas',
        empty:tank,
        details:{
            title:'Detalles de la transacción',
            header:[
                {id:1, label:'TANQUE'},
                {id:2, label:'TRUCK'},
                {id:3, label:'OPERACIONES'}
            ],
            body:{
                type:'Tipo',
                cylinder_tag:'ID del tanque',
                valve_tag:'ID de la válvula',
                tare:'Tara',
                name:'Nombre',
                gas_price:'Precio de gas',
                service_date:'Fecha de servicio',
                operation_date:'Fecha de operación',
                requested_kg:'Kilogramos solicitados',
                filled_kg:'Kilogramos llenados',
                price:'Precio'
            }
        

        },
        
    },
    modal:{
        add:{
            title:'Nueva venta',
        },
        steps:{
            create_sale:{
                title:'Nuevo tanque',
                alert_msg:'Estoy seguro de los datos',
                button:'Crear venta',
                client:'Cliente',
                client_button:'Elegir',
                form:{
                    tank_type_id:{label:'Tipo', helperText:'Campo requerido'},
                    payment_type_id:{label:'Método de pago', helperText:'Campo requerido'},
                    payment_ticket_number:{label:'Número de ticket', helperText:'Campo requerido'},
                },
                modal:{
                    title:'Elige al usuario',
                    placeholder:'Nombre o CPF',
                    nousers:'No existen usuarios registrados'
                }
            },
        }
    }
    
}

export default translations
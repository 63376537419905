import { Grid, Typography } from "@material-ui/core"

const DataDisplayer = ({label, data}) => {
    return(
        <Grid container spacing={3} alignItems='center'  >
            <Grid item ><Typography variant='body2' style={{color:'#A5A5A5'}}>{label}</Typography></Grid>
            <Grid item xs><Typography variant='body2' style={{color:'#22252F'}}>{data}</Typography></Grid>
        </Grid>
    )
}

export default DataDisplayer
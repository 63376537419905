import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard';
import ToolbarTable from '../../../../../components/Structure/Navigation/ToolbarTable';
import { actionTypes } from '../../../../../store/actions';
import SimpleTable from './components/SimpleTable';
import SimpleTableTanks from './components/SimpleTableTanks';
import useTransactions from './useTransactions';




const Transactions = ({user, history, language, content, catalogs}) => {

    const {system, view_data, actions, modals } = useTransactions({user, history})

    const [view, setView] = useState(1)

    let contentView = null

    switch(view){
        case 1:
            contentView = <SimpleTable 
                            content={content.gas.table}
                            loading={system.loading || system.sending}
                            catalogs={catalogs}
                            data={view_data.gas} 
                            filter={view_data.filter_gas}
                            tableFilter={view_data.tableFilter_gas}
                            total={view_data.total_gas} 
                            onUpdateTableFilter={actions.onUpdateTableFilterGas}
                            onChangeFilter={actions.onChangeFilterGas} 
                            onChangeSearch={actions.onChangeSearchGas}
                            />
            break
        case 2:
            contentView = <SimpleTableTanks 
                            content={content.tanks.table}
                            loading={system.loading || system.sending}
                            catalogs={catalogs}
                            data={view_data.tanks} 
                            filter={view_data.filter_tanks}
                            tableFilter={view_data.tableFilter_tanks}
                            total={view_data.total_tanks} 
                            onUpdateTableFilter={actions.onUpdateTableFilterTanks}
                            onChangeFilter={actions.onChangeFilterTanks} 
                            onChangeSearch={actions.onChangeSearchTanks}
                            />
            break
        default:
            break
    }

    return ( 
        <SimpleCard>
            <Typography variant='h6' style={{marginBottom:40}} >{content.title}</Typography>
            <ToolbarTable menuOptions={content.menu} selected={view} onChange={(_id)=>setView(_id)} />
            <div>
                {contentView}
            </div>
        </SimpleCard> 
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
import axios from 'axios'


export const nodata = '-'

export const CONFIG_SETTINGS = {
    MODE:'local',
    API_USERNAME:process.env.REACT_APP_API_USERNAME,
    API_PASSWORD:process.env.REACT_APP_API_PASSWORD,
    API_BEARER:`Basic ${btoa(process.env.REACT_APP_API_USERNAME+':'+process.env.REACT_APP_API_PASSWORD)}`,
    S3_SERVER_URL:process.env.REACT_APP_S3_SERVER_URL,
}


export const DASHBOARD_SETTINGS = {
    BOXSHADOW:'0px 12px 18px rgba(0, 0, 0, 0.1)',
    BOXSHADOW_PRIMARY:'0px 6px 14px rgba(202, 203, 251, 0.87)',
    DIVIDER_LINE:'1px solid #B3B3B3',
    COUNTRY_CODE:1,
    CURRENCY_ID:2,
    TOPBAR:{
        TRANSPARENT_MODE:'TRANSPARENT_MODE',
        WHITE_MODE:'WHITE_MODE',
        HEIGHT:72
    },
    PROFILE_DRAWER:{
        WIDTH:220,
        //MWIDTH:200,
        //SWIDTH:260
    }
}

export const USER_TYPES = {
    ADMIN:1,
    OPERATOR:2,
    CLIENT:3,
    
}

export const TOKEN_STATUSES = {
    VALID:1,
    EXPIRED:2,
    REAUTH:3,
    NOT_VALID:-1
}

export const CHECKOUT_STEPS = {
    SELECT_DELIVERY_METHOD:1,
    WAREHOUSE_DELIVERY:2,
    WAREHOUSE_PICKUP:3,
    ADDRESS_DELIVERY_SCHEDULE:4,
    ADDRESS_DELIVERY:5,
    ADDRESS_DELIVERY_MAP:6,
    PAYMENT_METHODS:7,
    OVERVIEW:10,
    COMPLETED:11
    
}

export const excel_column = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '', '', '', '', '',]

export const excel_template = [
    {key:'id_order', name:'ID'},
    {key:'compendium_key', name:'CLAVE DE COMPENDIO'},
    {key:'external_id', name:'ID EXTERNO'},
    {key:'exercise_year', name:'AÑO DE EJERCICIO', cat:'orders_years', cat_key:'exercise_year', col:'D'},
    {key:'order_applicant', name:'SOLICITANTE', cat:'applicants', cat_key:'order_applicant_id', col:'E'},
    {key:'order_event', name:'EVENTO', cat:'orders_events', cat_key:'order_event_id', col:'F'},
    {key:'event_identifier', name:'ID EVENTO'},
    {key:'contract_id', name:'P.O./CONTRATO'},
    {key:'replacement_order_id', name:'ORDEN DE REPOSICIÓN'},
    {key:'order_delivery_type', name:'TIPO DE ENTREGA', cat:'orders_delivery_types', cat_key:'order_delivery_type_id', col:'J'},
    {key:'delivery_institution', name:'INSTITUTO DE ENTREGA'},
    {key:'clue', name:'CLUE'},
    {key:'order_reception_date', name:'FECHA DE RECEPCIÓN'},
    {key:'order_expiration_date', name:'FECHA DE VENCIMIENTO'},
    {key:'deposit_date', name:'FECHA DE PAGO'},
    {key:'order_status', name:'ESTATUS DE PEDIDO', cat:'orders_statuses', cat_key:'order_status_id', col:'O'},
    {key:'invoiced', name:'FACTURADO', cat:'orders_invoiced', cat_key:'invoiced', col:'P'},
    {key:'units', name:'CANTIDAD'},
    {key:'amount', name:'MONTO'},
    {key:'delivery_date', name:'FECHA DE ENTREGA'},
    {key:'collection_entry_date', name:'FECHA DE INGRESO A COBRO'},
    {key:'payment_date', name:'FECHA LIMITE DE DE PAGO'},
]

export const ORDER_MATRIX = {
    'ID':'id_order',
    'CLAVE DE COMPENDIO':'compendium_key',
    'ID EXTERNO':'external_id',
    'AÑO DE EJERCICIO':'exercise_year',
    'SOLICITANTE':'order_applicant',
    'EVENTO':'order_event',
    'PO (CONTRATO)':'contract_id',
    'ORDEN DE REPOSICIÓN':'replacement_order_id',
    'TIPO DE ENTREGA':'order_delivery_type',
    'INSTITUTO DE ENTREGA':'delivery_institution',
    'CLUE':'clue',
    'FECHA DE RECEPCIÓN':'order_reception_date',
    'FECHA DE VENCIMIENTO':'order_expiration_date',
    'ESTATUS DE PEDIDO':'order_status',
    'FACTURADO':'invoiced',
    'CANTIDAD':'units',
    'MONTO':'amount',
    'CANTIDAD PAGADA':'units_paid',
    'MONTO PAGADO':'paid_amount',
    'FECHA DE ENTREGA':'delivery_date',
    'FECHA DE INGRESO A COBRO':'collection_entry_date',
    'FECHA DE PAGO':'payment_date'
}

export const ORDER_MATRIX_CAT = {
    'ID':null,
    'CLAVE DE COMPENDIO':null,
    'ID EXTERNO':null,
    'AÑO DE EJERCICIO':null,
    'SOLICITANTE':'applicants',
    'EVENTO':'orders_events',
    'PO (CONTRATO)':null,
    'ORDEN DE REPOSICIÓN':null,
    'TIPO DE ENTREGA':'orders_delivery_types',
    'INSTITUTO DE ENTREGA':null,
    'CLUE':null,
    'FECHA DE RECEPCIÓN':null,
    'FECHA DE VENCIMIENTO':null,
    'ESTATUS DE PEDIDO':'orders_statuses',
    'FACTURADO':'orders_invoiced',
    'CANTIDAD':null,
    'MONTO':null,
    'CANTIDAD PAGADA':null,
    'MONTO PAGADO':null,
    'FECHA DE ENTREGA':null,
    'FECHA DE INGRESO A COBRO':null,
    'FECHA DE PAGO':null
}



export const private_server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

export const public_server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization':CONFIG_SETTINGS.API_BEARER
    },
})

export const LAYOUT_SETTINGS = {
    BOXSHADOW: '0 10px 24px 0 rgba(82, 91, 115, .12)'
}

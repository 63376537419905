import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../../../components/Structure/Layouts/Page';
import { actionTypes } from '../../../store/actions';
import system_translations from '../../../texts/system_translations';
import SimpleTable from './components/SimpleTable';
import AddUserModal from './modals/AddUserModal/AddUserModal';
import DeleteUserModal from './modals/DeleteUserModal';
import useUsersView from './useUsersView';


const UsersView = ({user, history, language}) => {

    const content = system_translations[language].views.users
    const catalogs = system_translations[language].catalogs
    const {system, view_data, actions, modals } = useUsersView({user, history})

    return ( 
        <div style={{minHeight:'100vh'}}>
            <Page title={content.title} >
                <DeleteUserModal origin={view_data.selectedItem} open={modals.delete_register} message={content.message_modal}
                    onClose={() => actions.onUpdateModalStatus('delete_register', false)}  actions={actions}/>
                <AddUserModal open={modals.add_register} onSubmit={actions.onSubmit} actions={actions} content={content.modal}
                onClose={() => actions.onUpdateModalStatus('add_register', false)} user={user} catalogs={catalogs} />
                <div>
                    <SimpleTable 
                        content={content.table}
                        loading={system.loading || system.sending}
                        data={view_data.data} 
                        filter={view_data.filter}
                        tableFilter={view_data.tableFilter}
                        total={view_data.total} 
                        onSelectedItem={actions.onSelectItem}
                        onAddRegister={() => actions.onUpdateModalStatus('add_register', true)}
                        onUpdateTableFilter={actions.onUpdateTableFilter}
                        onChangeFilter={actions.onChangeFilter} 
                        onChangeSearch={actions.onChangeSearch}
                        onDeleteItem={actions.onDeleteSelected}
                        onChangeStatuses={actions.onChangeUserStatuses}
                        />
                </div>
            </Page>
        </div>  
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(UsersView);
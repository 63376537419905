import tank from '../../assets/icons/tank.svg'
import gas from '../../assets/icons/gas.svg'

const helperText = 'Este campo debe tener al menos 1 caracter y máximo 255'


export const translations = {
    title:'Información del cliente',
    menu:[{id:1, label:'Cuenta'}, {id:2, label:'Información fiscal'}, {id:3, label:'Mis tanques'}, {id:4, label:'Transacciones'}, {id:5, label:'Contraseña'}],
    breadcrumbs:{label:'Clientes', url:'/clients'},
    views:{
        account:{
            title:'Cuenta',
            form_title:'Información personal',
            form:{
                first_name:{label:'Nombre (s)', helperText:helperText},
                last_name:{label:'Apellido (s)', helperText:helperText},
                user_type_id:{label:'Tipo de usuario', helperText:'Debe seleccionar una opción'},
                email:{label:'Email', helperText:'Email no válido'},
                mobile:{label:'Teléfono', helperText:'Número de teléfono inválido'},
            },
            delete_button:'Eliminar',
            save_button:'Guardar',
            message_modal:'¿Estás seguro de eliminar a este usuario?'
        },
        tax_info:{
            title:'Información fiscal',
            form:{
                cnpj_cpf:{label:'CNPJ/CPJ', helperText:'CNPJ/CPJ no válido', key:'CD_CNPJCPF'},
                business_name:{label:'Razón social', helperText:helperText, key:'DS_RAZAO_SOCIAL'},
                commercial_name:{label:'Nombre comercial', label2:'Alias', helperText:helperText, key:'DS_FANTASIA'},
                state_registration:{label:'Registro estatal', helperText:'Este campo debe tener al menos 1 caracter y máximo 15', key:'CD_INSCR_ESTADUAL'},   
                municipal_registration:{label:'Registro municipal', helperText:'Este campo debe tener al menos 1 caracter y máximo 15', key:'CD_INSCR_MUNICIPAL'},
                ncea:{label:'Clasificación Nacional de actividades económicas', helperText:'Este campo debe tener al menos 1 caracter y máximo 10', key:'CD_CNAE'},
                address_line_1:{label:'Calle', helperText:helperText, key:'DS_LOGRADOURO'},
                ext_number:{label:'Num. Exterior', helperText:helperText, key:'NR_LOGRADOURO'},
                address_line_2:{label:'Información adicional', helperText:helperText, key:'DS_COMPLEMENTO'},
                neighborhood:{label:'Colonia', helperText:helperText, key:'DS_BAIRRO'},
                zip_code:{label:'Código postal', helperText:'Código postal no válido', key:'CD_CEP'},
                state_id:{label:'Estado (código)', helperText:'Debe seleccionar una opción', key:'CD_UF'},
                municipality_id:{label:'Municipio (código)', helperText:'Debe seleccionar una opción', key:'CD_MUNICIPIO'},
                email:{label:'Correo electrónico', helperText:'Correo no válido', key:'CD_EMAIL'},
                fixed_phone:{label:'Teléfono', helperText:'Número de teléfono inválido', key:'NR_FONE_FIXO'},
                cellphone:{label:'Celular', helperText:'Número de teléfono inválido', key:'NR_FONE_CEL'},
                registration_date:{label:'Fecha de registro', helperText:'Fecha no válida', key:'DT_CADASTRO'},
                devices:{label:'Dispositivos', helperText:helperText, key:'CHIPS_CLIENTE'},
            },
            delete_button:'Eliminar',
            save_button:'Guardar',
            message_modal:'¿Estás seguro de eliminar la información fiscal?'
        },
        tanks:{
            title:'Tanques del cliente',
            subtitle:'A continuación se desglosa la lista de tanques registrados de este cliente.',
            header:[
                {id:0, label:''},
                {id:1, label:'ID'},
                {id:2, label:'Tipo'},
                {id:3, label:'# Cilindro'},
                {id:4, label:'# Válvula'},
                {id:5, label:'Tara'},
                {id:6, label:'Fecha de venta'},
                {id:7, label:'Fecha de caducidad'},
                {id:8, label:'Acciones'},
            ],
            empty:tank, 
            nodata:'No existen tanques registrados',
            actions:[
                {value:1, label:'Subir evidencia', type:'upload', disabled:false},
                {value:2, label:'Descargar evidencia', type:'download', disabled:false},
                {value:3, label:'Eliminar', type:'delete', disabled:false},
            ],
            details:{
                title:'Detalles de la transacción',
                header:[
                    {id:1, label:'TANQUE'},
                    {id:2, label:'TRUCK'},
                    {id:3, label:'OPERACIONES'}
                ],
                body:{
                    type:'Tipo',
                    cylinder_tag:'ID del tanque',
                    valve_tag:'ID de la válvula',
                    tare:'Tara',
                    name:'Nombre',
                    gas_price:'Precio de gas',
                    service_date:'Fecha de servicio',
                    operation_date:'Fecha de operación',
                    requested_kg:'Kilogramos solicitados',
                    filled_kg:'Kilogramos llenados',
                    price:'Precio'
                }
            },
            add_button:'Agregar',
            modal:{
                subtitle:'Por favor ingrese los datos solicitados',
                add:{
                    title:'Agregar tanque',
                    button:'Agregar'
                },
                edit:{
                    title:'Editar tanque',
                    button:'Guardar'
                },
                form:{
                    tank_type_id:{label:'Tipo de tanque', helperText:helperText},
                    cylinder_tag:{label:'Id del cilindro', helperText:helperText},
                    valve_tag:{label:'Id de la válvula', helperText:helperText},
                    cylinder_tare:{label:'Tara', helperText:'Cantidad inválida'},
                    location:{label:'Ubicación', helperText:helperText},
                    name:{label:'Nombre', helperText:helperText},
                    seller_id:{label:'Vendedor', helperText:'Debe seleccionar una opción'},
                    price:{label:'Precio', helperText:helperText},
                },
                form_button:'Obtener'
            }
        },
        transactions:{
            title:'Transacciones',
            menu:[{id:1, label:'Gas'}, {id:2, label:'Tanques'}],
            gas:{
                message_modal:'¿Estás seguro de eliminar a este usuario?',
                table:{
                    header:[
                        {id:0, label:''},
                        {id:1, label:'ID'},
                        {id:2, label:'Fecha de op.'},
                        //{id:3, label:'Cliente'},
                        {id:4, label:'Vendedor'},
                        {id:5, label:'No. de kg'},
                        {id:6, label:'Precio'},
                        {id:7, label:'Método de pago'},
                        {id:8, label:'Ticket'},
                        {id:9, label:'¿Pagado?'},
                        {id:10, label:'¿Entregado?'},
                    ],
                    nodata:'No existen ventas registradas',
                    empty:gas,
                    details:{
                        title:'Detalles de la transacción',
                        header:[
                            {id:1, label:'TANQUE'},
                            {id:2, label:'TRUCK'},
                            {id:3, label:'OPERACIONES'}
                        ],
                        body:{
                            type:'Tipo',
                            cylinder_tag:'ID del tanque',
                            valve_tag:'ID de la válvula',
                            tare:'Tara',
                            name:'Nombre',
                            gas_price:'Precio de gas',
                            service_date:'Fecha de servicio',
                            operation_date:'Fecha de operación',
                            requested_kg:'Kilogramos solicitados',
                            filled_kg:'Kilogramos llenados',
                            price:'Precio de venta'
                        }
                    
    
                    }
                }
            },
            tanks:{
                message_modal:'¿Estás seguro de eliminar a este usuario?',
                table:{
                header:[
                    {id:0, label:''},
                    {id:1, label:'ID'},
                    {id:2, label:'Fecha de op.'},
                    //{id:3, label:'Cliente'},
                    {id:4, label:'Vendedor'},
                    {id:5, label:'No. de kg'},
                    {id:6, label:'Precio'},
                    {id:7, label:'Método de pago'},
                    {id:8, label:'Ticket'},
                    {id:9, label:'¿Pagado?'},
                    {id:10, label:'¿Entregado?'},
                ],
                nodata:'No existen ventas registradas',
                empty:tank,
                details:{
                    title:'Detalles de la transacción',
                    header:[
                        {id:1, label:'TANQUE'},
                        {id:2, label:'TRUCK'},
                        {id:3, label:'OPERACIONES'}
                    ],
                    body:{
                        type:'Tipo',
                        cylinder_tag:'ID del tanque',
                        valve_tag:'ID de la válvula',
                        tare:'Tara',
                        name:'Nombre',
                        gas_price:'Precio de gas',
                        service_date:'Fecha de servicio',
                        operation_date:'Fecha de operación',
                        requested_kg:'Kilogramos solicitados',
                        filled_kg:'Kilogramos llenados',
                        price:'Precio de venta'
                    }
                

                }
            }
            }
            
        },
        password:{
            title:'Cambio de contraseña',
            subtitle:'En esta sección podrás actualizar tu contraseña actual',
            form:{
                new_password:{label:'Nueva contraseña', helperText:'La contraseña debe contener por lo menos 6 caracteres'},
                previous_password:{label:'Contraseña anterior', helperText:'Campo requerido'},
                confirm_password:{label:'Confirmar contraseña', helperText:'Las contraseñas no coinciden'},
            },
            button:'Actualizar'
        }
    }
    
}

export default translations
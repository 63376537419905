import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { isFormValid, onGetCatalogs, onGetSelectedFormData, onGetSelectedFormDataKeys, onInitForm, onSetErrorsToForm } from "../../../../../shared/utility"
import { private_server } from "../../../../../config"
import {  onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"

const useTaxInfo = ({ content, catalogs, onError}) => {

    const {id} = useParams()
    //console.log(id)

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [check, setCheck] = useState(false)
    //const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    const [user, setUser] = useState(null)

    const [modals, setModals] = useState({
        delete:false,
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

  

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const _user = await private_server.get(`/user/${id}`)
                setUser(_user.data.data.user)
                const _states =  await private_server.get(`/user/states`)
                const _mun =  await private_server.get(`/user/municipalities`)
                let _form = onInitForm(form, _user.data.data.user, content.form)
                _form.state_id.options = onGetCatalogs(_states.data.data.states, 'id_state', 'name')
                _form.municipality_id.options = onGetCatalogs(_mun.data.data.municipalities, 'id_municipality', 'name')
                setForm(_form)
                setLoading(false)       
            } catch (error) {
                setLoading(false)
                console.log(error)
                const _error = onGetErrorMessage(error)
                onError(_error ? _error.message : null)
                //setError(_error ? _error.message : null)
            }
        },
        onChangeCheck: (event) => {
            setCheck(event.target.checked);
            let _form = {...form}
            _form.commercial_name.config.label = check ? content.form.commercial_name.label : content.form.commercial_name.label2
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            setForm(temp)
        },
        onUpdateModal: (_key, _value) => {
            let _modals = {...modals, [_key]:_value}
            setModals(_modals)
        },
        onSubmit: async () => {
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }
    
            //Extract data
            let data2send = onGetSelectedFormData(form, user)
            data2send.country_id = 1
            //let exp = /[./-_]|\s/
            //if(data2send.state_registration) data2send.state_registration = data2send.state_registration.split('.').join("")
            if(data2send.cnpj_cpf) data2send.cnpj_cpf = data2send.cnpj_cpf.split(/[./-]/).join("")
            if(data2send.state_registration) data2send.state_registration = data2send.state_registration.split(/[./-]/).join("")
            if(data2send.municipal_registration) data2send.municipal_registration = data2send.municipal_registration.split(/[./-]/).join("")
            if(data2send.zip_code) data2send.zip_code = data2send.zip_code.split(/[./-]/).join("")
            console.log(data2send) 

            //let data2sendExt = onGetSelectedFormDataKeys(form, user)
            //data2sendExt.CD_PAIS = 1058
            //console.log(data2sendExt)

            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                const _user = await private_server.patch(`/user/${id}`, data2send)
           
                setUser(_user.data.data.user)
                if(_user.data.data.user.fiscal_ready === 0){
                    await private_server.post(`/user/${id}/client/fiscal`)
                }else{
                    await private_server.patch(`/user/${id}/client/fiscal`)
                }
                
                
                //await actions.onInitModule()
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                //setError(_error ? _error.message : null)   
                onError(_error ? _error.message : null)
            }
            setSending(false)
        }
    }

    const system = {loading, sending}
   

    return {system, form, user, actions, modals, check}
}

export default useTaxInfo

const formData = {
    cnpj_cpf: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'cnpj_cpf',
            type: 'text',
            fullWidth: true,
           
        },
        rules: {
            type: 'distance',
            min:11, max: 20
        }
    },
    business_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'business_name',
            type: 'text',
            fullWidth: true,
           
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    commercial_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'commercial_name',
            type: 'text',
            fullWidth: true,
         
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    state_registration: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'state_registration',
            type: 'number',
            fullWidth: true,
           
        },
        rules: {
            type: 'distance',
            min: 1, max: 15
        }
    },
    municipal_registration: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'municipal_registration',
            type: 'number',
            fullWidth: true,
         
        },
        rules: {
            type: 'distance',
            min: 1, max: 15
        }
    },
    ncea: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ncea',
            type: 'text',
            fullWidth: true,
           
        },
        rules: {
            type: 'distance',
            min: 1, max: 10
        }
    },
    address_line_1: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'address_line_1',
            type: 'text',
            fullWidth: true,
         
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    ext_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ext_number',
            type: 'number',
            fullWidth: true,
           
        },
        rules: {
            type: 'distance',
            min: 1, max: 4
        }
    },
    address_line_2: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'address_line_2',
            type: 'text',
            fullWidth: true,
         
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    neighborhood: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'neighborhood',
            type: 'text',
            fullWidth: true,
           
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    zip_code: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'zip_code',
            type: 'text',
            fullWidth: true,
         
        },
        rules: {
            type: 'distance',
            min: 5, max: 20
        }
    },
    state_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'state_id',
            type: 'select',
            fullWidth: true, 
        },
        rules: {
            type: 'select',
            //min: 1, max: 256
        }
    },
    municipality_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'municipality_id',
            type: 'select',
            fullWidth: true,  
        },
        rules: {
            type: 'select',
            //min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
        
        },
        rules: {
            type: 'email',
        }
    },
    fixed_phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'fixed_phone',
            type: 'number',
            fullWidth: true,     
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    cellphone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'cellphone',
            type: 'number',
            fullWidth: true,     
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    registration_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'registration_date',
            type: 'date',
            fullWidth: true,
         
        },
        rules: {
            type: 'date',
            //min: 1, max: 256
        }
    },
    
    /* mobile_country_code: {
        value: '52',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'mobile_country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }

    }, */
    
}
import React, { useEffect, useState } from 'react'
import { MenuItem, TextField, withStyles, Grid, Typography, Select } from '@material-ui/core'
import { ruleValidation } from './customFunctions'
import phonecodes from 'country-codes-list'

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:12,
        '& fieldset': {
          //borderColor: 'red',
        },
        '&:hover fieldset': {
          //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          //borderColor: 'green',
        },
      },
    },
  })(TextField);

const InputPhoneCodeForm = props => {

    const {data, onChange, startAdornment, endAdornment} = props
    const [options, setOptions] = useState([])

    useEffect(() => {
      const _cats = phonecodes.all()
      console.log(_cats)
      let _temp = []
      _cats.forEach(item => {
        _temp.push({
          value:item.countryCallingCode,
          label:item.countryNameEn, //countryNameLocal
        })
      })
      _temp.sort((a,b)=>{
        if(a.label > b.label){
          return 1
        }
        if(a.label < b.label){
          return -1
        }
        return 0
      })
      setOptions(_temp)
    }, [])

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited} = data

    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''

    const _options = options

    return(
        <div>
            <Select  value={value} variant='outlined'  
            renderValue={(selected)=>{
              console.log(selected)
              return(
                <Typography variant='subtitle2'>
                  {`${selected}`}
                </Typography>
              )
              
            }}
            input={<CssTextField error={isError} {...interConfig} select onChange={onInnerChange} variant='outlined'
            InputProps={
              {
                  startAdornment:startAdornment ? startAdornment : undefined,
                  endAdornment:endAdornment ? endAdornment : undefined,
              }} />}
            

            
            >
                {_options.map(item => {
                    return(
                        <MenuItem key={item.value.toString()} value={item.value}>
                            <Grid container>
                              <Grid item>
                                <Typography variant='subtitle2' style={{width:80}}>
                                  {`+${item.value}`}
                                </Typography>
                              
                              </Grid>
                              <Grid item>
                              {item.label}
                              </Grid>
                            </Grid>
                            
                        </MenuItem>
                    )
                })}
            </Select>
        </div>
    )
}

export default InputPhoneCodeForm
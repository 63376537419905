import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import SimpleModal from './SimpleModal'
import InputFile from '../Forms/InputFile'
import system_translations from '../../texts/system_translations';
import ActionModalBar from '../Actions/ActionModalBar';



const FileModal = ({ open, onClose, onSubmit, language, loading, error, file, onDelete, onChange}) => {

    const content = system_translations[language].modals.upload_file

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant='h6' color='primary'>{content.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputFile id='file' file={file} label={content.input_file} 
                            onChange={onChange} onDelete={onDelete}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar btnLabel={content.button} loading={loading} error={error} onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
     );
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}
 

export default connect(mapStateToProps)(FileModal)

import { useState, useEffect } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"
import { private_server, public_server } from "../../../config"
import { generic_errors } from "../../../texts/esp/genericErrors"
import queryString from 'query-string'
import { USER_TYPES } from "../../../shared/enums"

const useAdministratorsView = ({history}) => {
    
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})
    const [filter, setFilter] = useState({query:''})
    const [selectedItem, setSelectedItem] = useState(null)
    const [modals, setModals] = useState({
        add_register: false,
        edit_register:false,
        delete_register:false
    })
    const [person, setPerson] = useState(null)

    useEffect(()=>{window.scrollTo(0,0)},[])

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const onGetParams = () => {
        const {limit, offset} = tableFilter
        const {query} = filter

        let andFiltersArgs = [
            {"field":"user_type_id","operator":"=", "value":USER_TYPES.ADMIN},
            {"field":"user_type_id","operator":"=", "value":USER_TYPES.OPERATOR}
        ]
     
        let prefinalArgs = {"OR":andFiltersArgs}
        const param2send = {limit:limit, offset:limit*offset,  order:1}  
        let params = `?${queryString.stringify(param2send)}&filter=${encodeURIComponent(JSON.stringify(prefinalArgs))}`
        

        let finalArgs = {}
    
        if(query){
            //andFiltersArgs.push({"field":"first_name","operator":"LIKE", "value":`%${query}%`})
            finalArgs = {"AND":[
                {"field":"first_name","operator":"LIKE", "value":`%${query}%`},
                prefinalArgs
            ]}
            params = `?${queryString.stringify(param2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
        }
 
        

        return params
    }
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/user/all${_params}`)
                const temp = users_request.data.data
                setTotal(temp.count)
                setData(temp.users)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            setLoading(false)
        },
        onUpdateUsers: async() => {
            setSending(true)
            try {
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/user/all${_params}`)
                const temp = users_request.data.data
                setTotal(temp.count)
                setData(temp.users)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            setSending(false)
        },
        onChangeSearch:(data) => {
            //console.log(data)
            let temp = {...filter}
            temp.query = data
            setFilter(temp)
        },
        onDeleteSelected:(data) => {
            console.log(data)
            setSelectedItem(data)
            actions.onUpdateModalStatus('delete_register', true)
        },
        onSelectItem: (data) =>{
            const {id_user} = data
            history.push(`users/${id_user}`)
        },
        onChangeUserStatuses:async(status, new_value, _id) => {
            let data2send = {}
            if(status === 'enabled') data2send={user_status_id: new_value} 
            //setLoading(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/user/${_id}`, data2send)
                actions.onInitModule()
            } catch (error) {
                console.log(error)
            }
            //setLoading(false)
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onChangeFilter: (data) => setFilter(data)
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, filter, selectedItem}

    return {system, actions, view_data, modals}
}

export default useAdministratorsView